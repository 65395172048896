export function addMonths(date: Date, monthDelta: number) {
  const result = new Date(date.getTime());
  result.setMonth(result.getMonth() + monthDelta);
  return result;
}

export function addDays(date: Date, dayDelta: number) {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() + dayDelta);
  return result;
}

export function addDelta(date: Date, delta: number) {
  return new Date(date.getTime() + delta);
}

// Returns delta of dateB - dateA
export function dateDelta(dateA: Date, dateB: Date): number {
  return dateB.getTime() - dateA.getTime();
}

export function minDate(a: Date, b: Date): Date {
  if (a.getTime() < b.getTime()) {
    return a;
  }
  return b;
}

export function maxDate(a: Date, b: Date): Date {
  if (a.getTime() > b.getTime()) {
    return a;
  }
  return b;
}

// Returns if first is after second
export function isAfter(first: Date, second: Date): boolean {
  return first.getTime() > second.getTime();
}
export function isBefore(first: Date, second: Date): boolean {
  return first.getTime() < second.getTime();
}

export function startOfDay(d: Date): Date {
  const start = new Date(d.getTime());
  start.setHours(0, 0, 0, 0);
  return start;
}

export function endOfDay(d: Date): Date {
  const eod = new Date(d.getTime());
  eod.setHours(23, 59, 59, 0);
  return eod;
}
export function morningAfter(d: Date): Date {
  const start = new Date(d.getTime());
  start.setHours(7, 0, 0, 0);
  return addDays(start, 1);
}
