import firebase from "firebase";
import { getCollectionName } from "client/firebase/db";
import { getUserUID, isLoggedIn } from "client/firebase/auth";
import _ from "lodash";

type DocumentData = firebase.firestore.DocumentData;
type DocumentReference = firebase.firestore.DocumentReference;
type UpdateData = firebase.firestore.UpdateData;
type CollectionReference<T> = firebase.firestore.CollectionReference<T>;
type QuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>;

interface BaseObject {
  id: string | undefined;
  created: Date | undefined;
}

export default class ObjectStore<T extends BaseObject> {
  private readonly name: string;
  private readonly collection: string;
  private readonly toFirestore: (item: T) => firebase.firestore.DocumentData;
  private readonly fromFirestore: (
    id: string,
    data: firebase.firestore.DocumentData
  ) => T;

  constructor(
    name: string,
    toFirestore: (item: T) => DocumentData,
    fromFirestore: (id: string, data: DocumentData) => T
  ) {
    this.name = name;
    this.collection = getCollectionName(name);
    this.toFirestore = toFirestore;
    this.fromFirestore = fromFirestore;
  }

  fullToFirestore = (modelObject: T): firebase.firestore.DocumentData => {
    const baseData = this.toFirestore(modelObject);
    return _.extend(baseData, {
      user: getUserUID(),
      created: modelObject.created,
    });
  };

  fullFromFirestore = (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): T => {
    const data = snapshot.data();
    const custom = this.fromFirestore(snapshot.id, data);
    return _.extend(custom, {
      id: snapshot.id,
      created: new Date(data.created?.seconds * 1000),
    });
  };

  converter() {
    return {
      toFirestore: this.fullToFirestore,
      fromFirestore: this.fullFromFirestore,
    };
  }

  collectionRef(): CollectionReference<T> {
    return firebase
      .firestore()
      .collection(this.collection)
      .withConverter<T>(this.converter());
  }

  add(item: Omit<T, "created" | "id">): Promise<DocumentReference> {
    const fullItem: T = {
      created: new Date(),
      ...item,
    } as T;
    return this.collectionRef().add(fullItem);
  }

  set(item: T): Promise<void> {
    return this.collectionRef().doc(item.id).set(item);
  }

  update(id: string, updateData: UpdateData): Promise<void> {
    return this.collectionRef().doc(id).update(updateData);
  }

  delete(id: string): Promise<void> {
    return this.collectionRef().doc(id).delete();
  }

  async get(id: string): Promise<T | undefined> {
    const doc = await this.collectionRef().doc(id).get();
    return doc.data();
  }

  fetchAll(): Promise<T[]> {
    return new Promise((resolve, reject) => {
      if (!isLoggedIn()) {
        return resolve([]);
      }

      this.collectionRef()
        .where("user", "==", getUserUID())
        .get()
        .then((snapshot) => resolve(this.extractItems(snapshot)))
        .catch((err) => reject(err));
    });
  }

  extractItems(snapshot: QuerySnapshot<T>): T[] {
    const items: T[] = [];
    snapshot.forEach((result) => items.push(result.data()));
    return items;
  }

  subscribeAll(fullyReplace: (items: T[]) => void) {
    this.collectionRef().onSnapshot((snapshot) =>
      fullyReplace(this.extractItems(snapshot))
    );
  }

  find(field: string, value: string) {
    return this.collectionRef().where(field, "==", value).get();
  }
}

export function convertFirebaseTime(timeData: any): Date {
  return timeData ? new Date(timeData.seconds * 1000) : new Date();
}
