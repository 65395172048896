import { useHistory } from "react-router-dom";
import React from "react";

interface LBProps {
  path: string;
  text: string;
  selected?: boolean;
}

export const LinkOrButton = ({ path, text, selected }: LBProps) => {
  const history = useHistory();
  if (selected) {
    return <b className="link-or-button link-or-button-selected">{text}</b>;
  }
  return (
    <button className="link-or-button" onClick={() => history.push(path)}>
      {text}
    </button>
  );
};
