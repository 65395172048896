import {
  computeDateVal,
  getTargets,
  PreferredGraphType,
  Result,
  updatePreferredGraphType,
} from "client/firebase/models/result";
import { Progress } from "client/firebase/models/progress";
import React, { useState } from "react";
import _ from "lodash";
import { addDays, isAfter } from "lib/date_utils/arithmetic";
import { Target, TargetState } from "client/firebase/models/target";
import { ToggleButton } from "components/ToggleButton";
import {
  AUTO_VIEWPORT,
  DateValue,
  GraphboardView,
  GraphLine,
  Layer,
  ViewportConfig,
} from "components/graphboard/GraphboardView";
import COLOR from "lib/color";
import {
  cropSegmentByValue,
  GraphSegment,
  shiftSegment,
} from "components/graphboard/segment";
import { minMax } from "lib/date_utils/math";
import { getDimensions } from "components/graph";

interface Props {
  result: Result;
  progress: Progress[];
}

export const ResultGraph = ({ result, progress }: Props) => {
  const now = new Date();
  const [showAll, setShowAll] = useState(
    !!result.complete ||
      result.preferredGraphType === PreferredGraphType.ProgressFull
  );
  const showOptions = !result.complete;

  // Viewport
  let viewport: ViewportConfig = AUTO_VIEWPORT;
  if (!showAll) {
    viewport = {
      type: "custom",
      minDate: addDays(now, -14),
      maxDate: addDays(now, 7),
    };
  }
  const size = getDimensions();
  const layers = createLayers(result, progress, showAll);

  return (
    <div className="result-graph">
      {showOptions ? (
        <ToggleButton
          toggleOffText="Focus around now"
          toggleOnText="Show full graph"
          onChange={(showAll) => {
            updatePreferredGraphType(
              result.id,
              showAll
                ? PreferredGraphType.ProgressFull
                : PreferredGraphType.DefaultProgress
            );
            setShowAll(showAll);
          }}
          initialValue={showAll}
        />
      ) : null}
      <GraphboardView viewport={viewport} layers={layers} size={size} />,
    </div>
  );
};

function createProgressLine(result: Result, progress: Progress[]): GraphLine {
  const points: DateValue[] = progress.map((p) => ({
    date: p.date,
    value: p.computedValue ?? 0,
  }));
  if (!result.complete && progress.length > 0) {
    const latestProgress = _.sortBy(progress, "date")[progress.length - 1];
    points.push({ date: new Date(), value: latestProgress.computedValue ?? 0 });
  }

  return {
    type: "line",
    name: "progress-line",
    points,
    style: { color: COLOR.Blue },
  };
}

function createLayers(
  result: Result,
  progress: Progress[],
  showAll: boolean
): Layer[] {
  let targets = getTargets(result);
  if (!showAll) {
    const activeTargets = targets.filter(
      (target) => target.state != TargetState.Aborted
    );
    if (activeTargets.length > 0) {
      targets = activeTargets;
    }
  }
  const targetLayer: Layer = {
    elements: _.flatten(targets.map((t) => createTargetSegments(result, t))),
    style: { strokeWidth: 5, strokeCap: "round" },
  };
  const progressLayer: Layer = {
    elements: [createProgressLine(result, progress)],
  };

  return [targetLayer, progressLayer];
}

function createTargetSegments(result: Result, target: Target): GraphSegment[] {
  const segments: GraphSegment[] = [];
  const targetId = target.id;

  const { startDate, startValue, endDate, endValue, state } = target;

  const targetSegment: GraphSegment = {
    type: "segment",
    startDate,
    startY: startValue,
    endDate,
    endY: endValue,
    name: targetId + "-main",
    style: {
      color: COLOR.Green,
    },
  };
  segments.push(targetSegment);

  if (isAfter(target.endDate, new Date()) && state == TargetState.Active) {
    // Segments for steps
    const dayStep =
      computeDateVal(target, addDays(startDate, 1)) -
      computeDateVal(target, startDate);
    const expectedStep = (dayStep < 0 ? -1 : 1) * result.expectedStep;

    let stepLine = shiftSegment(
      targetSegment,
      -expectedStep,
      targetId + "-step"
    );
    const [minY, maxY] = minMax(target.startValue, target.endValue);
    stepLine.style.color = COLOR.Orange;

    // Crop vertically to not go "below initial" at the start
    const cropped = cropSegmentByValue(stepLine, minY, maxY);
    if (cropped) {
      segments.push(cropped);
    }
  }
  return segments;
}
