import React from "react";
import { VegaLite } from "react-vega";
import { TopLevelSpec } from "vega-lite";
import { UnitSpec } from "vega-lite/build/src/spec";
import { Encoding } from "vega-lite/build/src/encoding";
import { Axis } from "vega-lite/build/src/axis";
import { Scale } from "vega-lite/build/src/scale";
import { enforceExhaustive } from "lib/type_checking";
import { isAfter, isBefore } from "lib/date_utils/arithmetic";
import { cropSegmentByDate, GraphSegment } from "components/graphboard/segment";
import { LayerStyle, LineStyle } from "components/graphboard/style";
import { interpolateFromDates } from "client/firebase/models/result";
import _ from "lodash";

interface AutoViewport {
  type: "auto";
}

export const AUTO_VIEWPORT: AutoViewport = { type: "auto" };

interface CustomViewport {
  type: "custom";
  minDate: Date;
  maxDate: Date;
}

export type ViewportConfig = AutoViewport | CustomViewport;

export interface DateValue {
  date: Date;
  value: number;
}

export interface GraphLine {
  type: "line";
  name: string;
  points: DateValue[];
  style: LineStyle;
}

type GraphElement = GraphSegment | GraphLine;

export interface Layer {
  elements: GraphElement[];
  style?: LayerStyle;
}

export interface Size {
  width: number;
  height: number;
}

interface Props {
  viewport: ViewportConfig;
  layers: Layer[];
  size: Size;
}

export function GraphboardView(props: Props): JSX.Element {
  const { viewport, layers, size } = props;

  validateViewport(viewport);

  const viewportLayers = applyViewport(layers, viewport);
  const data = createData(viewportLayers);
  const spec = createSpec(size, viewportLayers);

  return (
    <div className="graphboard-view-wrapper">
      <VegaLite key="chart" actions={false} spec={spec} data={data} />
    </div>
  );
}

const TIME_FIELD_NAME = "time"; // X
const PROGRESS_FIELD_NAME = "progress"; // Y
const ELEMENT_TYPE_FIELD_NAME = "element-type"; // Color

interface DataPoint {
  [TIME_FIELD_NAME]: Date;
  [PROGRESS_FIELD_NAME]: number;
  [ELEMENT_TYPE_FIELD_NAME]: string;
}

type LayerData = { [layerName: string]: DataPoint[] };

function createData(layers: Layer[]): LayerData {
  const dataByLayers: LayerData = {};

  layers.forEach((layer, layerIndex) => {
    const { elements } = layer;
    const layerData: DataPoint[] = [];
    elements.forEach((element) => {
      const elementData = renderElement(element);
      layerData.push(...elementData);
    });
    dataByLayers[layerName(layerIndex)] = layerData;
  });

  return dataByLayers;
}

function validateViewport(viewport: ViewportConfig) {
  if (viewport.type == "custom") {
    const { minDate, maxDate } = viewport;
    if (isAfter(minDate, maxDate)) {
      throw new Error("required: minDate < maxDate " + viewport);
    }
  }
}

function applyViewport(layers: Layer[], viewport: ViewportConfig): Layer[] {
  return layers.map((layer) => {
    return Object.assign({}, layer, {
      elements: applyViewportToElements(layer.elements, viewport),
    });
  });
}

function applyViewportToElements(
  elements: GraphElement[],
  viewport: ViewportConfig
): GraphElement[] {
  const output: GraphElement[] = [];
  elements.forEach((e) => {
    const cropped = applyViewportToElement(e, viewport);
    if (cropped != null) {
      output.push(cropped);
    }
  });
  return output;
}

function applyViewportToElement(
  e: GraphElement,
  viewport: ViewportConfig
): GraphElement | null {
  if (viewport.type == "auto") {
    return e;
  } else if (viewport.type == "custom") {
    const { minDate, maxDate } = viewport;
    return cropElement(e, minDate, maxDate);
  } else {
    enforceExhaustive(viewport);
  }
}

function cropElement(
  e: GraphElement,
  cropMinDate: Date,
  cropMaxDate: Date
): GraphElement | null {
  if (e.type == "line") {
    return cropLineByDate(e, cropMinDate, cropMaxDate);
  } else if (e.type == "segment") {
    return cropSegmentByDate(e, cropMinDate, cropMaxDate);
  } else {
    enforceExhaustive(e);
  }
}

function cropLineByDate(
  line: GraphLine,
  minDate: Date,
  maxDate: Date
): GraphLine | null {
  const points = _.sortBy(line.points, "date");
  // Index of first and last full data points to keep
  const startIndex = _.findIndex(points, (p) => isAfter(p.date, minDate));
  const endIndex = _.findLastIndex(points, (p) => isBefore(p.date, maxDate));

  if (startIndex == -1 || endIndex == -1) {
    return null;
  }

  const newPoints = points.slice(startIndex, endIndex + 1);
  if (startIndex > 0) {
    const minDateValue = interpolateFromDateValues(
      points[startIndex - 1],
      points[startIndex],
      minDate
    );
    newPoints.unshift({ date: minDate, value: minDateValue });
  }
  if (endIndex < points.length - 1) {
    const maxDateValue = interpolateFromDateValues(
      points[endIndex],
      points[endIndex + 1],
      maxDate
    );
    newPoints.push({ date: maxDate, value: maxDateValue });
  }

  return Object.assign({}, line, { points: newPoints });
}

function interpolateFromDateValues(
  dateValue1: DateValue,
  dateValue2: DateValue,
  date: Date
): number {
  if (isBefore(date, dateValue1.date) || isAfter(date, dateValue2.date)) {
    throw Error(
      `Dates misordered. Required: ${dateValue1.date} < ${date} < ${dateValue2.date}`
    );
  }
  return interpolateFromDates(
    dateValue1.date,
    dateValue1.value,
    dateValue2.date,
    dateValue2.value,
    date
  );
}

function renderElement(element: GraphElement): DataPoint[] {
  if (element.type == "line") {
    return element.points.map((point) => {
      const { date, value } = point;
      return createDataPoint(date, value, element.name);
    });
  } else if (element.type == "segment") {
    return [
      createDataPoint(element.startDate, element.startY, element.name),
      createDataPoint(element.endDate, element.endY, element.name),
    ];
  } else {
    enforceExhaustive(element);
  }
}

function createDataPoint(date: Date, value: number, name: string): DataPoint {
  return {
    [TIME_FIELD_NAME]: date,
    [PROGRESS_FIELD_NAME]: value,
    [ELEMENT_TYPE_FIELD_NAME]: name,
  };
}

function createSpec(size: Size, layers: Layer[]): TopLevelSpec {
  const { width, height } = size;

  const layerSpec = layers.map((layer, i) =>
    createLayerSpec(layerName(i), layer)
  );

  const encoding = createEncoding(layers);

  return {
    width,
    height,
    encoding: encoding,
    layer: layerSpec,
  };
}

function createEncoding(layers: Layer[]): Encoding<any> {
  const layerColorMapping = createColorMapping(layers);

  return formatAxes({
    x: { field: TIME_FIELD_NAME, type: "temporal" },
    y: {
      field: PROGRESS_FIELD_NAME,
      type: "quantitative",
      scale: { zero: false },
    },
    color: {
      field: ELEMENT_TYPE_FIELD_NAME,
      type: "nominal",
      scale: layerColorMapping,
      legend: null,
    },
    tooltip: [
      { field: TIME_FIELD_NAME, type: "temporal" },
      { field: PROGRESS_FIELD_NAME, type: "quantitative" },
      { field: ELEMENT_TYPE_FIELD_NAME, type: "nominal" },
    ],
  });
}

function createColorMapping(layers: Layer[]): Scale {
  const domain: string[] = [];
  const range: string[] = [];
  layers.map((layer) => {
    const { elements } = layer;
    elements.map((element) => {
      domain.push(element.name);
      range.push(element.style.color.hex);
    });
  });

  return { domain, range };
}

function formatAxes<T extends string>(encoding: Encoding<T>): Encoding<T> {
  const axisOverrides = [
    formatAxis(encoding, "x", { title: null }),
    formatAxis(encoding, "y", { title: null }),
  ];

  return Object.assign({}, encoding, ...axisOverrides);
}

function formatAxis<T extends string, key extends keyof Encoding<T>>(
  encoding: Encoding<T>,
  key: key,
  overrides: Axis
): Encoding<T>[key] {
  return Object.assign({}, encoding[key], overrides);
}

function createLayerSpec(layerName: string, layer: Layer): UnitSpec {
  return {
    data: { name: layerName },
    mark: {
      type: "line",
      ...(layer.style ?? {}),
    },
  };
}

function layerName(layerIndex: number): string {
  return "layer-" + layerIndex;
}
