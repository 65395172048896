import React, { useEffect, useState } from "react";
import {
  addTarget,
  deleteResult,
  getLatestTarget,
  getTargets,
  markComplete,
  migrateLegacyTarget,
  recalculateProgress,
  recalculateTargetBaselines,
  Result,
  ResultTargetType,
  updateComment,
  updateExpectedStep,
  updateResultType,
  updateSnooze,
} from "client/firebase/models/result";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  latestProgress,
  Progress,
  ProgressType,
} from "client/firebase/models/progress";
import ProgressCreator from "components/ProgressCreator";
import ProgressList from "components/ProgressList";
import { ResultGraph } from "components/ResultGraph";
import { TargetInfo } from "components/TargetInfo";
import TargetIndicator from "components/TargetIndicator";
import { MagicInput } from "components/MagicInput";
import { ToggleButton } from "components/ToggleButton";
import { ConfirmButton } from "components/ConfirmButton";
import { connect } from "react-redux";
import { State } from "client/data/state";
import _ from "lodash";
import { RateGraph } from "components/RateGraph";
import { formattedStepTime } from "client/dagbok/analysis/steps";
import { CategoryDisplay } from "components/CategoryDisplay";
import { decreasingTarget, Target } from "client/firebase/models/target";
import { TargetTypeToggle } from "components/TargetTypeToggle";
import { DAY } from "lib/date_utils/time";
import { SnoozeControl } from "components/SnoozeControl";
import ErrorBoundary from "components/ErrorBoundary";

interface Props {
  result: Result | undefined;
  progress: Progress[];
}

function getPrompt(
  latest: Progress | undefined,
  latestTarget: Target | undefined
): string {
  if (!latest || latest.type !== ProgressType.Delta) {
    return "";
  }
  if (latestTarget && decreasingTarget(latestTarget)) {
    return "-";
  }
  return "+";
}

export const PureResultInfo = ({ result, progress }: Props) => {
  const history = useHistory();
  const [showHistory, setShowHistory] = useState(false);
  const [graphRate, setGraphRate] = useState(false);

  useEffect(() => {
    document.title = `Dagbok - ${result?.text}`;
  }, [result]);

  if (!result) {
    return <div>Loading...</div>;
  }

  migrateLegacyTarget(result);
  const latest = latestProgress(progress);

  const allTargets = _.reverse(getTargets(result));
  const latestTarget = allTargets.length ? [allTargets[0]] : [];
  const hasOld = allTargets.length > 1;

  const sortedProgress = _.sortBy(progress, "date");

  const prompt = getPrompt(latest, latestTarget[0]);

  return (
    <div>
      <div className="result-info">
        <h2>{result.text}</h2>
        <div className="result-comment">
          <MagicInput
            initialValue={result.comment || ""}
            placeholder="Note"
            onSubmit={(comment) => updateComment(result?.id, comment)}
          />
        </div>
        <div className="result-info-category">
          Category:&nbsp;
          <CategoryDisplay
            resultId={result.id}
            category={result.category ?? ""}
          />
          &nbsp;
        </div>
        <TargetIndicator
          showRate
          result={result}
          target={getLatestTarget(result)}
          current={latest?.computedValue}
          complete={result.complete}
          showBar
        />
        <SnoozeControl
          result={result}
          onSnoozeChange={(newSnooze) => updateSnooze(result.id, newSnooze)}
        />
        <hr />
        <button
          className="no-mobile"
          onClick={() => addTarget(result, progress)}
        >
          Add Target
        </button>
        {(showHistory ? allTargets : latestTarget).map((target) => (
          <TargetInfo result={result} target={target} key={target.id} />
        ))}
        {hasOld ? (
          <React.Fragment>
            <ToggleButton
              className="no-mobile"
              toggleOffText="Hide history"
              toggleOnText="Show history"
              onChange={setShowHistory}
              initialValue={showHistory}
            />
            <br />
          </React.Fragment>
        ) : null}
        <b>
          Typical <span className="no-mobile">minimum </span>step:
        </b>
        <MagicInput
          initialValue={String(result.expectedStep)}
          className="step-input"
          onSubmit={(v) => {
            const step = parseInt(v);
            if (step) {
              updateExpectedStep(result.id, step);
            }
          }}
        />
        <span>{formattedStepTime(result)}</span> &nbsp;
        <TargetTypeToggle
          initialType={result.type ?? ResultTargetType.Finite}
          onNewType={(newType) => {
            updateResultType(result.id, newType);
          }}
        />
        <hr />
        <ToggleButton
          toggleOffText="Absolute graph"
          toggleOnText="Rate Graph"
          onChange={setGraphRate}
          initialValue={graphRate}
        />
        <ErrorBoundary>
          {graphRate ? (
            <RateGraph
              result={result}
              progress={progress}
              unit={result.units || "units"}
              timeUnit={result.preferredRateUnit || DAY}
            />
          ) : (
            <ResultGraph result={result} progress={progress} />
          )}
        </ErrorBoundary>
        <div>
          <p className="no-mobile">
            <ToggleButton
              toggleOffText="Mark incomplete"
              toggleOnText="Mark complete"
              onChange={(newComplete) => {
                markComplete(result.id, newComplete);
                if (newComplete) {
                  history.push({ pathname: "/results" });
                }
              }}
              initialValue={!!result.complete}
              requireConfirm
              isGood
            />
          </p>
          <ConfirmButton
            className="delete-button no-mobile"
            onConfirm={() => {
              deleteResult(result?.id);
              history.push({ pathname: "/results" });
            }}
            label="Delete result"
          />
          <br className="no-mobile" />
          <button
            className="no-mobile"
            onClick={() => {
              recalculateProgress(result, progress);
            }}
          >
            Recalculate Progress
          </button>
          <button
            className="no-mobile"
            onClick={() => {
              recalculateTargetBaselines(result, sortedProgress);
            }}
          >
            Recalculate Baseline
          </button>
          <br className="no-mobile" />
          {!result.complete &&
          latest?.computedValue === latestTarget[0]?.endValue ? (
            <ToggleButton
              className="mark-complete-prompt"
              toggleOffText="Mark incomplete"
              toggleOnText="Done, mark complete?"
              onChange={(newComplete) => {
                markComplete(result.id, newComplete);
                if (newComplete) {
                  history.push({ pathname: "/results" });
                }
              }}
              initialValue={!!result.complete}
              requireConfirm
              isGood
            />
          ) : null}
        </div>
      </div>
      <ProgressCreator result={result} prefix={`${result?.text}: ${prompt}`} />
      <ProgressList resultIdSet={{ [result.id]: true }} />
    </div>
  );
};

interface OuterProps {
  resultId: string;
}

export default connect(
  (
    { progress: { items: progressItems }, result: { items } }: State,
    { resultId }: OuterProps
  ) => {
    const result = items.find((i) => i.id === resultId);
    return {
      result,
      progress: progressItems.filter((p) => p.resultId === resultId),
    };
  },
  (_) => ({})
)(PureResultInfo);
