import React from "react";
import ProgressRow from "components/ProgressRow";

import { connect } from "react-redux";
import { State } from "client/data/state";
import { Progress } from "client/firebase/models/progress";
import { doUpdateProgress } from "client/data/actions/progress";
import { Result } from "client/firebase/models/result";
import _ from "lodash";

interface Props {
  resultIdSet?: { [resultId: string]: boolean };
  loaded?: boolean;
  progress: Progress[];
  results: Result[];
  onUpdateProgress: (id: string, text: string, date: Date) => void;
}

export function PureProgressList({
  loaded,
  progress,
  results,
  onUpdateProgress,
  resultIdSet,
}: Props) {
  const events = { onUpdateProgress };
  if (!!resultIdSet) {
    progress = progress.filter((item) => item.resultId in resultIdSet);
  }

  let result: Result | undefined = undefined;
  if (resultIdSet && _.size(resultIdSet) === 1) {
    result = _.find(results, (r) => r.id in resultIdSet);
  }

  const LoadingRow = (
    <div className="loading-item">
      <span className="glow-checkbox" />
      <span className="glow-text">
        <span>Loading</span> <span>cool</span> <span>state</span>
      </span>
    </div>
  );

  if (!loaded) {
    return (
      <div className="list-items">
        {LoadingRow}
        {LoadingRow}
        {LoadingRow}
        {LoadingRow}
        {LoadingRow}
        {LoadingRow}
      </div>
    );
  }

  if (progress.length === 0) {
    return (
      <div className="list-items">
        <div className="wrapper-message">
          <span className="icon-sync" />
          <div className="title-message">You've recorded no progress</div>
          <div className="subtitle-message">Start tracking!</div>
        </div>
      </div>
    );
  }

  const orderedProgress = progress.sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );

  return (
    <div className="list-items">
      {orderedProgress.map((progress) => (
        <ProgressRow
          key={progress.id}
          progress={progress}
          results={results}
          result={result}
          {...events}
        />
      ))}
    </div>
  );
}

export default connect(
  ({
    progress: { items: progressItems, loaded },
    result: { items: resultItems },
  }: State) => {
    return {
      progress: progressItems,
      loaded,
      results: resultItems,
    };
  },
  (_) => {
    return {
      onUpdateProgress: doUpdateProgress(),
    };
  }
)(PureProgressList);
