import React from "react";
import {
  computeEndFromEndDate,
  computeEndFromEndValue,
  deleteTarget,
  Result,
  updateStartDate,
  updateStartValue,
  updateTargetFields,
  updateTargetState,
} from "client/firebase/models/result";
import DatePicker from "react-datepicker";
import TargetRateIndicator from "components/TargetRateIndicator";
import { Target, TargetState } from "client/firebase/models/target";
import { MagicInput } from "components/MagicInput";
import { ConfirmButton } from "components/ConfirmButton";
import { ToggleButton } from "components/ToggleButton";

interface Props {
  result: Result;
  target: Target;
}

export const TargetInfo = ({ target, result }: Props) => {
  return (
    <div className="target-info">
      <p>
        <b className="result-target-label">
          {target.endDate > new Date() ? "Active:" : "Old:"}
        </b>
        <span className="date-picker-wrapper">
          <DatePicker
            className="result-date-picker"
            selected={target.startDate}
            disabledKeyboardNavigation
            onChange={(date: Date) => {
              date.setHours(23);
              date.setMinutes(59);
              updateStartDate(target, date);
            }}
            customInput={<SimpleDateInput />}
          />
        </span>
        <MagicInput
          className="target-value-input"
          initialValue={target.startValue.toFixed(0)}
          onSubmit={(v) => updateStartValue(target, parseInt(v))}
          validate={(v) => !Number.isNaN(parseInt(v))}
        />
        <span className="no-mobile">
          {" "}
          (base {target.startBaseline?.toFixed(1)}){" "}
        </span>
        <span> &#x27A1; </span>
        <MagicInput
          className="target-value-input"
          initialValue={target.endValue.toFixed(0)}
          onSubmit={(v) => {
            const { endDate, endValue } = computeEndFromEndValue(
              result,
              target,
              parseInt(v)
            );
            if (endDate !== target.endDate || endValue !== target.endValue) {
              updateTargetFields(target, { endDate, endValue });
            }
          }}
          validate={(v) => !Number.isNaN(parseInt(v))}
          controlled
        />
        <span className="date-picker-wrapper">
          <DatePicker
            disabledKeyboardNavigation
            className="result-date-picker"
            selected={target.endDate}
            onChange={(date: Date) => {
              date.setHours(23);
              date.setMinutes(59);
              const { endDate, endValue } = computeEndFromEndDate(
                result,
                target,
                date
              );
              if (endDate !== target.endDate || endValue !== target.endValue) {
                updateTargetFields(target, { endDate, endValue });
              }
            }}
            customInput={<SimpleDateInput />}
          />
        </span>
        <TargetRateIndicator result={result} target={target} />
        &nbsp;
        <ConfirmButton onConfirm={() => deleteTarget(target)} label="X" />
        &nbsp;
        <ToggleButton
          toggleOffText="Enable"
          toggleOnText="Abort"
          onChange={(newIsAborted) => {
            updateTargetState(
              target,
              newIsAborted ? TargetState.Aborted : TargetState.Active
            );
          }}
          initialValue={target.state === TargetState.Aborted}
        />
      </p>
    </div>
  );
};

const SimpleDateInput = ({
  value,
  onClick,
}: {
  value?: Date;
  onClick?: () => {};
}) => (
  <button className="result-date-input" onClick={onClick}>
    {value
      ? value.toLocaleString("en-US", {
          month: "numeric",
          day: "numeric",
          weekday: "short",
        })
      : "??/??/????"}
  </button>
);
