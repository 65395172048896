import React from "react";
import { Link } from "react-router-dom";
import { Result } from "client/firebase/models/result";
import { Progress } from "client/firebase/models/progress";
import { FlatResultList } from "components/FlatResultList";

export interface ResultWithProgress {
  result: Result;
  progress: Progress[];
  timeDelta: number;
  valueDelta: number; // always positive (?)
}

export interface ResultGroup {
  category: string;
  label: string;
  results: ResultWithProgress[];
  childGroups?: ResultGroup[];
}

interface Props {
  groups: ResultGroup[];
}

export const ResultList = ({ groups }: Props) => {
  return (
    <div>
      {groups.map((g) => (
        <ResultGroupList group={g} />
      ))}
    </div>
  );
};

export const ResultGroupList = ({ group }: { group: ResultGroup }) => {
  return (
    <div>
      {group.label ? (
        <Link
          to={`/category/${group.category}`}
          className="result-list-group-label"
        >
          {group.label}
        </Link>
      ) : null}
      <FlatResultList results={group.results} />
      {group.childGroups ? (
        <div>
          {group.childGroups.map((cg) => (
            <ResultGroupList group={cg} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
