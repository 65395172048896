import { ProgressState } from "client/data/state";
import { Action, ActionType } from "client/data/actions/actions";

export const progressReducer = (
  state: ProgressState,
  action: Action
): ProgressState => {
  switch (action.type) {
    case ActionType.OverwriteProgress:
      return {
        ...state,
        items: action.progress || [],
        loaded: action.progress.length > 0,
      };

    default:
      return state;
  }
};
