import React from "react";
import { shortFormatWithUnits } from "lib/format";
import { isMobile } from "lib/device/mobile";

interface Props {
  chunks: Chunk[];
  onlyImportantLabels?: boolean;
}

export function ChunkedBar(props: Props): JSX.Element {
  const { chunks, onlyImportantLabels } = props;

  const minLabelSize = onlyImportantLabels ? 0.08 : isMobile() ? 0.1 : 0.03;

  let totalRatio = 0;
  let lastLabelRatio = 0;
  return (
    <div className="result-indicator">
      {chunks.map((chunk, i) => {
        totalRatio += chunk.ratio;
        const showLabel =
          (chunk.importantLabel || !onlyImportantLabels) &&
          totalRatio - lastLabelRatio > minLabelSize;
        if (showLabel) {
          lastLabelRatio = totalRatio;
        }

        return <ChunkUi key={i} chunk={chunk} showLabel={showLabel} />;
      })}
    </div>
  );
}

export interface Chunk {
  ratio: number;
  value: number;
  color: string;
  units?: string;
  formattedValue?: string;
  importantLabel?: boolean;
}

export interface ChunkUiProps {
  chunk: Chunk;
  showLabel: boolean;
}

export const ChunkUi = ({
  chunk: { ratio, value, units, color, formattedValue },
  showLabel,
}: ChunkUiProps) => {
  const style = {
    width: `${(ratio * 100).toFixed(1)}%`,
  };
  return (
    <div
      className={`result-progress-chunk result-progress-${color}`}
      style={style}
    >
      {showLabel ? (
        <div className="result-indicator-text">
          {formattedValue ?? shortFormatWithUnits(value, units)}
        </div>
      ) : (
        <span>&nbsp;</span>
      )}
    </div>
  );
};
