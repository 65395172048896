import React from "react";
import { useLocation } from "react-router-dom";
import CategoryInfo from "components/CategoryInfo";

export const CategoryPage = () => {
  const { pathname } = useLocation();
  const category = pathname.slice("/category/".length);
  return (
    <div className="category-page">
      <CategoryInfo category={category} />
    </div>
  );
};
