import { Progress } from "client/firebase/models/progress";
import React from "react";
import _ from "lodash";
import { VegaLite } from "react-vega";
import { interpolateFromDates, Result } from "client/firebase/models/result";

interface Props {
  progress: Progress[];
  results: Result[];
}

export const spec = {
  width: 400,
  height: 200,
  encoding: {
    x: { field: "time", type: "temporal" },
    color: {
      field: "result",
      type: "nominal",
    },
    y: {
      field: "progress",
      type: "quantitative",
      aggregate: "sum",
    },
    order: {
      field: "resultOrder",
      type: "quantitative",
    },
    tooltip: [
      { field: "progress", type: "quantitative" },
      { field: "result", type: "nominal" },
    ],
  },
  layer: [
    {
      data: { name: "targetTable" },
      mark: { type: "line", strokeWidth: 5, strokeCap: "round" },
    },
    { data: { name: "progressTable" }, mark: "area" },
  ],
};

export function createData(progress: Progress[], results: Result[]): any {
  const sortedProgress = _.sortBy(progress, "date");

  const ridSet: { [resultId: string]: boolean } = {};
  const ridOrder: string[] = [];

  let prevDate = new Date(0);
  const allDates: Date[] = [];

  sortedProgress.forEach((p) => {
    // Ordering
    if (!ridSet[p.resultId]) {
      ridOrder.push(p.resultId);
    }
    ridSet[p.resultId] = true;

    let date = p.date;
    if (date.getTime() !== prevDate.getTime()) {
      allDates.push(date);
    }
    prevDate = date;
  });

  let resultsByResultId = _.keyBy(results, "id");

  const tableData: any[] = [];
  const progressByResult = _.groupBy(progress, "resultId");
  ridOrder.forEach((resultId) => {
    const rProgress = _.sortBy(progressByResult[resultId], "date");
    let lastRDate = new Date(0);
    let lastRValue = 0;
    let nextProgress = rProgress[0];
    let nextProgressIndex = 1;

    const getValue = (d: Date): number => {
      if (nextProgress === undefined) {
        // Past end of progress
        return lastRValue;
      }

      // Iterate through progresses
      while (d.getTime() > nextProgress.date.getTime()) {
        // Save the point
        lastRValue = lastRValue + Math.abs(nextProgress.computedDelta ?? 0);
        lastRDate = nextProgress.date;

        // Move on
        nextProgress = rProgress[nextProgressIndex];
        nextProgressIndex++;
        if (!nextProgress) {
          return lastRValue;
        }
      }

      // Haven't gotten to first result yet, don't show anything
      if (lastRDate.getTime() === 0) {
        return lastRValue;
      }

      // Okay, now d <= nextProgress.date.getTime(), get the interpolation
      return interpolateFromDates(
        lastRDate,
        lastRValue,
        nextProgress.date,
        lastRValue + Math.abs(nextProgress.computedDelta ?? 0),
        d
      );
    };

    allDates.forEach((d) => {
      tableData.push({
        time: d,
        result: resultsByResultId[resultId]?.text ?? "unknown",
        progress: getValue(d),
        type: "progress",
      });
    });
  });

  return {
    progressTable: tableData,
  };
}

export function addDimensions(spec: any): any {
  spec.encoding.color.legend = null;
  if (window.innerWidth <= 400) {
    spec.width = 260;
    spec.height = (spec.width * 2) / 3;
  } else {
    spec.width = Math.min(window.innerWidth - 200, 900);
    spec.height = Math.min(spec.width / 2, 360);
  }
  return spec;
}

export const StackedProgressGraph = ({ progress, results }: Props) => {
  return (
    <div className="result-graph">
      <VegaLite
        actions={false}
        spec={addDimensions(spec) as any}
        data={createData(progress, results)}
      />
      ,
    </div>
  );
};
