import ObjectStore from "client/firebase/obj_store";
import {
  getOrCreateResultId,
  getResultProgress,
} from "client/data/actions/result";
import _ from "lodash";
import {
  getLatestTarget,
  markComplete,
  Result,
} from "client/firebase/models/result";
import * as ProgressEntryParser from "lib/parsers/ProgressEntryParser";

export enum ProgressType {
  Unknown = "UNKNOWN",
  Absolute = "ABSOLUTE",
  Delta = "DELTA",
}

export interface Progress {
  id: string;
  created: Date;
  date: Date;
  text: string;
  action: string;
  resultId: string;
  type: ProgressType;
  value: number;
  comment: string;

  computedValue?: number;
  computedDelta?: number;
}

const progressRegex = RegExp(
  "^([^:]*): ([+\\-])?([0-9]+[h.]?[0-9]*)([ ]+//.+)?$"
);

export function parseProgressText(
  text: string
): {
  action: string;
  value: number;
  type: ProgressType;
  comment: string;
} | null {
  try {
    const result = ProgressEntryParser.parse(text);
    console.log(result);
  } catch (e) {
    console.log(e);
  }

  if (text.trim() === "delete") {
    return {
      action: "delete",
      value: 0,
      type: ProgressType.Unknown,
      comment: "",
    };
  }

  const matches = text.match(progressRegex);
  if (matches === null) {
    return null;
  }

  let strValue = matches[3];
  let progressValue = parseInt(strValue.trim(), 10);
  // Handle time
  if (strValue.match(/h/)) {
    const strParts = strValue.split("h");
    progressValue = 60 * parseInt(strParts[0], 10) + parseInt(strParts[1], 10);
  }
  if (strValue.match(/\./)) {
    progressValue = parseFloat(strValue);
  }

  const progressType =
    matches[2]?.length === 1 ? ProgressType.Delta : ProgressType.Absolute;
  if (progressType === ProgressType.Delta && matches[2] === "-") {
    progressValue *= -1;
  }

  const comment = matches[4] ?? "";

  return {
    action: matches[1].trim(),
    value: progressValue,
    type: progressType,
    comment: comment.replace("//", "")?.trim(),
  };
}

const ProgressStore = new ObjectStore(
  "progress",
  function toFirestore({
    text,
    resultId,
    computedValue,
    computedDelta,
    date,
  }: Progress) {
    const parsed = parseProgressText(text);
    if (!parsed) {
      throw Error("Received malformed progress.ts text");
    }
    const { action, value, type, comment } = parsed;
    return {
      text,
      action,
      value: value,
      type: type,
      comment,
      resultId,
      computedValue,
      computedDelta,
      date,
    };
  },
  function fromFirestore(id, data) {
    const {
      type,
      text,
      action,
      value,
      resultId,
      computedValue,
      computedDelta,
      date,
      created,
      comment,
    } = data;
    return {
      text,
      date: new Date((date || created)?.seconds * 1000),
      action,
      type,
      value,
      resultId,
      computedValue,
      computedDelta,
      comment: comment ?? "",
    } as Progress;
  }
);
export default ProgressStore;

export function finishTarget(
  result: Result,
  finishEditing: (input: string) => void
) {
  const latestTarget = getLatestTarget(result);
  if (latestTarget) {
    markComplete(result.id, true);
    finishEditing(`${result.text}: ${latestTarget.endValue}`);
  }
}

export function deleteProgress(id: string) {
  ProgressStore.delete(id);
}

export async function updateProgress(
  id: string,
  newText: string,
  newDate: Date
) {
  let currentProgress = await ProgressStore.get(id);

  let parsed: Partial<Progress>;
  parsed = parseProgressText(newText) || {};
  parsed.resultId = await getOrCreateResultId(parsed.action as string);
  let priorProgress = await getResultProgress(parsed.resultId);
  priorProgress = priorProgress.filter(
    (p) =>
      p.date.getTime() < (currentProgress ? currentProgress.date.getTime() : 0)
  );

  let computedValue = parsed.value || 0;
  let computedDelta = parsed.value || 0;
  if (parsed.type === ProgressType.Delta) {
    if (priorProgress.length) {
      computedValue +=
        priorProgress[priorProgress.length - 1].computedValue || 0;
    }
  } else {
    if (priorProgress.length) {
      computedDelta -=
        priorProgress[priorProgress.length - 1].computedValue || 0;
    } else {
      computedDelta = 0;
    }
  }

  return ProgressStore.update(id, {
    text: newText,
    date: newDate,
    ...parsed,
    computedValue,
    computedDelta,
  });
}
export function latestProgress(rProgress: Progress[]): Progress | undefined {
  rProgress = _.sortBy(rProgress, "date");
  if (rProgress.length > 0) {
    return rProgress[rProgress.length - 1];
  }
  return undefined;
}
export function latestProgressValue(rProgress: Progress[]): number | undefined {
  return latestProgress(rProgress)?.computedValue;
}
