import React from "react";
import { Link } from "react-router-dom";
import TargetIndicator from "components/TargetIndicator";
import { getLatestTarget } from "client/firebase/models/result";
import { latestProgressValue } from "client/firebase/models/progress";
import { ResultWithProgress } from "components/ResultList";

interface Props {
  results: ResultWithProgress[];
}

export function FlatResultList(props: Props): JSX.Element {
  const { results } = props;
  return (
    <div>
      {results.map(({ result, progress }) => {
        return (
          <Link to={`/result/${result.id}`} className="list-item">
            <div className="result-list-item">
              <div className="list-item-text result-list-link">
                {result.text}
              </div>
              <TargetIndicator
                result={result}
                target={getLatestTarget(result)}
                current={latestProgressValue(progress)}
                complete={result.complete}
                onlyImportantLabels
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
}
