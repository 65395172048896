import React from "react";
import ProgressRow from "components/ProgressRow";
import { connect } from "react-redux";
import { State } from "client/data/state";
import { Progress, ProgressType } from "client/firebase/models/progress";
import { doCreateProgress } from "client/data/actions/progress";
import { Result } from "client/firebase/models/result";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  onCreateProgress: (
    title: string,
    date: Date,
    navigateResult: (resultId: string) => void
  ) => void;
  results: Result[];
  prefix?: string;
  result?: Result;
}

interface OuterProps {
  prefix?: string;
  result?: Result;
}

export default connect(
  ({ progress, result }: State, { prefix }: OuterProps) => ({
    results: result.items,
    prefix,
  }),
  (_) => ({
    onCreateProgress: doCreateProgress(),
  })
)(PureProgressCreator);

export function PureProgressCreator(props: Props) {
  const { onCreateProgress, results, prefix, result } = props;

  const emptyProgress: Progress = {
    action: "",
    created: new Date(),
    date: new Date(),
    id: "new-id",
    text: prefix ?? "",
    type: ProgressType.Absolute,
    value: 0,
    resultId: "",
    comment: "",
  };
  const { pathname } = useLocation();
  const history = useHistory();
  return (
    <div className="task-creator">
      <ProgressRow
        createMode
        onUpdateProgress={(id, title, date: Date) => {
          onCreateProgress(title, date, (resultId) => {
            const newPage = `/result/${resultId}`;
            console.log({ newPage, pathname });
            if (pathname !== newPage) {
              history.push(newPage);
            }
          });
        }}
        progress={emptyProgress}
        results={results}
        result={result}
      />
    </div>
  );
}
