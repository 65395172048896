import React from "react";
import ResultInfo from "components/ResultInfo";
import { useParams } from "react-router-dom";

export default function ResultPage() {
  const { resultId } = useParams();
  return (
    <div className="result-page">
      <ResultInfo resultId={resultId} />
    </div>
  );
}
