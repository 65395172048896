// Actions
import { createStore } from "redux";
import { emptyState } from "client/data/state";
import { reducer } from "client/data/reducers/root";

export default createStore(
  reducer,
  emptyState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
