import { LineStyle } from "components/graphboard/style";
import { isAfter, maxDate, minDate } from "lib/date_utils/arithmetic";
import {
  interpolateFromDates,
  interpolateToDates,
} from "client/firebase/models/result";
import { clamp } from "lib/date_utils/math";

export interface GraphSegment {
  type: "segment";
  name: string;
  startDate: Date;
  endDate: Date;
  startY: number;
  endY: number;
  style: LineStyle;
}

function interpolateSegment(segment: GraphSegment, date: Date): number {
  const { startDate, endDate, startY, endY } = segment;
  return interpolateFromDates(startDate, startY, endDate, endY, date);
}
function interpolateSegmentDate(segment: GraphSegment, v: number): Date {
  const { startDate, endDate, startY, endY } = segment;
  // noinspection JSSuspiciousNameCombination
  return interpolateToDates(startY, startDate, endY, endDate, v);
}

export function shiftSegment(
  segment: GraphSegment,
  deltaY: number,
  newName: string
): GraphSegment {
  const { startY, endY } = segment;
  return Object.assign({}, segment, {
    startY: startY + deltaY,
    endY: endY + deltaY,
    name: newName,
    style: Object.assign({}, segment.style),
  });
}

export function cropSegmentByDate(
  segment: GraphSegment,
  cropMinDate: Date,
  cropMaxDate: Date
): GraphSegment | null {
  if (isAfter(segment.startDate, segment.endDate)) {
    throw new Error("Required: endDate > startDate: " + segment);
  }
  // Fully cropped
  if (
    isAfter(segment.startDate, cropMaxDate) ||
    isAfter(cropMinDate, segment.endDate)
  ) {
    console.log(segment, { cropMinDate, cropMaxDate });
    return null;
  }
  const newStartDate = maxDate(segment.startDate, cropMinDate);
  const newEndDate = minDate(segment.endDate, cropMaxDate);
  return Object.assign({}, segment, {
    startDate: newStartDate,
    endDate: newEndDate,
    startY: interpolateSegment(segment, newStartDate),
    endY: interpolateSegment(segment, newEndDate),
  });
}
export function cropSegmentByValue(
  segment: GraphSegment,
  cropMinY: number,
  cropMaxY: number
): GraphSegment | null {
  // Fully cropped
  if (
    Math.max(segment.startY, segment.endY) < cropMinY ||
    Math.min(segment.startY, segment.endY) > cropMaxY
  ) {
    return null;
  }

  const newStartY = clamp(segment.startY, cropMinY, cropMaxY);
  const newEndY = clamp(segment.endY, cropMinY, cropMaxY);
  return Object.assign({}, segment, {
    startY: newStartY,
    endY: newEndY,
    startDate: interpolateSegmentDate(segment, newStartY),
    endDate: interpolateSegmentDate(segment, newEndY),
  });
}
