// If after noon, due = need by midnight
import * as time from "lib/date_utils/time";

// 12am -> 4am: DUE = TODAY = 12pm (today)
// 4am  -> 12pm: DUE = 8am (tomorrow): TODAY = 12pm (tomorrow)
// 12pm -> 12am: DUE = 8am (tomorrow): TODAY = 12pm (tomorrow)

// If before noon, due = need by 6pm
// Otherwise due = 8am tomorrow
export function getDueCutoff(): number {
  const now = new Date();
  let target = new Date();

  if (now.getHours() < 4) {
    target.setHours(12);
  } else if (now.getHours() < 12) {
    target.setHours(16);
  } else {
    target.setHours(24 + 8);
  }
  target.setMinutes(0);
  target.setSeconds(0);
  return target.getTime() - now.getTime();
}

// Today === by 12pm (tomorrow)
export function getTodayCutoff(): number {
  const now = new Date();
  let target = new Date();
  if (now.getHours() > 4) {
    target.setDate(target.getDate() + 1);
  }
  target.setHours(12);
  target.setMinutes(0);
  target.setSeconds(0);

  return target.getTime() - now.getTime();
}

export function getTomorrowCutoff(): number {
  return getTodayCutoff() + time.DAY;
}
