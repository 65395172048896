import { Action, ActionType } from "client/data/actions/actions";
import { Dispatch } from "redux";
import {
  PreferredGraphType,
  Result,
  ResultStore,
} from "client/firebase/models/result";
import ProgressStore, {
  Progress,
  ProgressType,
} from "client/firebase/models/progress";
import _ from "lodash";
import { addDays, endOfDay } from "lib/date_utils/arithmetic";
import { TargetState } from "client/firebase/models/target";

export const overwriteResultAction = (items: Result[]): Action => ({
  type: ActionType.OverwriteResults,
  result: items,
});

export function overwriteResultsFromServer(dispatch: Dispatch) {
  ResultStore.fetchAll().then((items) =>
    dispatch(overwriteResultAction(items))
  );
}

export function subscribeToResults(dispatch: Dispatch) {
  ResultStore.subscribeAll((items) => dispatch(overwriteResultAction(items)));
}

// Next: make endValue given by the text
export async function getOrCreateResultId(resultText: string) {
  const results = await ResultStore.find("text", resultText);
  if (results.empty) {
    const startDate = endOfDay(addDays(new Date(), -1));
    const endDate = addDays(startDate, 7);
    const docRef = await ResultStore.add({
      text: resultText,
      units: "",
      expectedStep: 1,
      targets: {
        initial: {
          startDate,
          startValue: 0,
          endDate,
          endValue: 36,
          state: TargetState.Active,
        },
      },
      preferredGraphType: PreferredGraphType.DefaultProgress,
      comment: "",
      snoozedUntil: null,
    });
    const resultId = docRef.id;

    await ProgressStore.add({
      text: `${resultText}: 0 // Initial`,
      comment: "",
      action: resultText,
      resultId,
      date: startDate,
      type: ProgressType.Absolute,
      value: 0,
      computedValue: 0,
      computedDelta: 0,
    });
    return docRef.id;
  } else {
    return results.docs[0].id;
  }
}

export async function getResultProgress(resultId: string): Promise<Progress[]> {
  const results = await ProgressStore.find("resultId", resultId);
  if (results.empty) {
    return [];
  }
  return _.sortBy(
    results.docs.map((doc) => doc.data()),
    "date"
  );
}
