function getDbEnv(): string {
  return process.env.REACT_APP_FIREBASE_DATABASE_ENV || "dev";
}

const validEnv: { [env: string]: boolean } = {
  dev: true,
  prod: true,
};

function validateEnv() {
  if (!validEnv[getDbEnv()]) {
    throw Error("Requested env is not valid");
  }
}
validateEnv();

export function getCollectionName(name: string): string {
  return `${getDbEnv()}--${name}`;
}
