import { ResultState } from "client/data/state";
import { Action, ActionType } from "client/data/actions/actions";

export const resultReducer = (
  state: ResultState,
  action: Action
): ResultState => {
  switch (action.type) {
    case ActionType.OverwriteResults:
      return {
        ...state,
        items: action.result || [],
        loaded: action.result.length > 0,
      };

    default:
      return state;
  }
};
