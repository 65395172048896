import ObjectStore, { convertFirebaseTime } from "client/firebase/obj_store";
import ProgressStore, {
  Progress,
  ProgressType,
} from "client/firebase/models/progress";
import _ from "lodash";
import {
  StoredTarget,
  Target,
  TargetState,
} from "client/firebase/models/target";
import { addDays, addDelta } from "lib/date_utils/arithmetic";
import firebase from "firebase";
import { DAY } from "lib/date_utils/time";

export enum ResultTargetType {
  Unknown = "UNKNOWN",
  Ongoing = "ONGOING", // Default === FixedRate?
  Finite = "FINITE", // Fixed Y
  FixedTime = "FIXED_TIME", // Fixed X
  FixedRate = "FIXED_RATE", // Fixed rate
}

export enum PreferredGraphType {
  Unknown = 0,
  DefaultProgress = 1, // Zoomed in progress
  ProgressFull = 2,
}

export interface Result {
  id: string;
  created: Date;
  text: string;
  units?: string;
  complete?: boolean;
  category?: string;
  type?: ResultTargetType;
  expectedStep: number;
  targets: { [id: string]: StoredTarget };
  preferredGraphType: PreferredGraphType;
  preferredRateUnit?: number;
  comment: string;
  snoozedUntil: Date | null;
}

export function getLatestTarget(result: Result): Target | undefined {
  const allTargets = getTargets(result);
  const lastTarget = allTargets[allTargets.length - 1];
  if (!lastTarget || lastTarget.state === TargetState.Aborted) {
    return undefined;
  }
  return lastTarget;
}

export function getTargets(result: Result): Target[] {
  return _.sortBy(
    _.toPairs(result.targets).map((pair) => {
      const id = pair[0];
      const storedTarget = pair[1];
      return {
        ...storedTarget,
        id,
        resultId: result.id,
        units: result.units ?? "",
      };
    }),
    (d) => d.startDate.getTime()
  );
}

export const ResultStore = new ObjectStore(
  "result",
  function toFirestore({
    text,
    complete,
    units,
    targets,
    category,
    type,
    expectedStep,
    preferredGraphType,
    preferredRateUnit,
    comment,
    snoozedUntil,
  }: Result) {
    return {
      text,
      targets,
      complete: complete ?? false,
      units: units ?? "",
      category: category ?? "",
      type: type ?? ResultTargetType.Finite,
      expectedStep: expectedStep ?? 1,
      preferredGraphType:
        preferredGraphType ?? PreferredGraphType.DefaultProgress,
      preferredRateUnit: preferredRateUnit ?? 0,
      comment: comment ?? "",
      snoozedUntil,
    };
  },
  function fromFirestore(id, data) {
    const {
      text,
      complete,
      units,
      targets,
      category,
      type,
      expectedStep,
      preferredGraphType,
      preferredRateUnit,
      comment,
      snoozedUntil,
      /* Legacy Target Info */
      targetValue,
      startValue,
      targetDate: rawTargetDate,
      startDate: rawStartDate,
      /* End Legacy Target Info */
    } = data;

    let fullTargets = targets ?? {};
    if (
      rawStartDate !== undefined &&
      rawTargetDate !== undefined &&
      targetValue !== undefined &&
      startValue !== undefined &&
      !fullTargets["legacyMigrated"]
    ) {
      fullTargets = Object.assign(
        {
          legacyToMigrate: {
            startDate: rawStartDate,
            startValue,
            endDate: rawTargetDate,
            endValue: targetValue,
          },
        },
        fullTargets
      );
    }

    _.mapValues(fullTargets, (v) => {
      v.startValue = v.startValue || 0;
      v.endValue = v.endValue || 0;
      v.endDate = convertFirebaseTime(v.endDate);
      v.startDate = convertFirebaseTime(v.startDate);
    });

    return {
      text,
      complete,
      units,
      targets: fullTargets,
      type: type || ResultTargetType.Finite,
      expectedStep: expectedStep || 1,
      category,
      preferredGraphType:
        preferredGraphType || PreferredGraphType.DefaultProgress,
      preferredRateUnit,
      comment,
      snoozedUntil: snoozedUntil
        ? convertFirebaseTime(snoozedUntil)
        : undefined,
    } as Result;
  }
);

export function hasTarget(result: Result): boolean {
  return _.size(result.targets) > 0;
}

export function clampDateToTarget(target: Target, d: Date): Date {
  if (d.getTime() < target.startDate.getTime()) {
    return target.startDate;
  }
  if (d.getTime() > target.endDate.getTime()) {
    return target.endDate;
  }
  return d;
}

export function clampToTarget(target: Target, v: number): number {
  const minVal = Math.min(target.startValue, target.endValue);
  const maxVal = Math.max(target.startValue, target.endValue);
  if (v < minVal) {
    return minVal;
  }
  if (v > maxVal) {
    return maxVal;
  }
  return v;
}

export function computeValDate(target: LineInfo, v: number): Date {
  if (target.endValue === target.startValue) {
    return target.startDate;
  }
  return new Date(
    interpolate(
      target.startValue,
      target.startDate.getTime(),
      target.endValue,
      target.endDate.getTime(),
      v
    )
  );
}

interface LineInfo {
  startDate: Date;
  startValue: number;
  endDate: Date;
  endValue: number;
}

export function computeDateVal(target: Target, queryDate: Date): number {
  const { startDate, endDate, startValue, endValue } = target;
  if (queryDate.getTime() < startDate.getTime()) {
    return startValue;
  }
  if (queryDate.getTime() > endDate.getTime()) {
    return endValue;
  }
  const valRange = (endValue || 0) - (startValue || 0);
  const timeRange = endDate.getTime() - startDate.getTime();
  const targetRange = queryDate.getTime() - startDate.getTime();

  return (startValue || 0) + (targetRange * valRange) / timeRange;
}

export function recalculateTargetBaselines(
  result: Result,
  progress: Progress[]
): void {
  getTargets(result).forEach((target) => {
    recalculateTargetBaseline(target, progress);
  });
}

export function recalculateTargetBaseline(
  target: Target,
  progress: Progress[]
): void {
  const { value } = interpolateProgress(progress, target.startDate);
  if (value !== target.startBaseline) {
    updateStartBaseline(target, value);
  }
}

export function recalculateProgress(
  result: Result,
  progress: Progress[]
): void {
  const sortedProgress = _.sortBy(progress, "date");
  if (!sortedProgress.length) {
    return;
  }
  const first = sortedProgress[0];
  updateIfNeeded(first, {
    newValue: first.value,
    newDelta: first.type === ProgressType.Delta ? first.value : 0,
  });
  let prevValue = first.value;
  sortedProgress.slice(1).forEach((p) => {
    const newValue =
      p.type === ProgressType.Delta ? prevValue + p.value : p.value;
    updateIfNeeded(p, {
      newValue,
      newDelta: newValue - prevValue,
    });
    prevValue = newValue;
  });

  // Check all the baselines
  getTargets(result).forEach((target) => {
    const { value: newBaseline } = interpolateProgress(
      progress,
      target.startDate
    );
    if (newBaseline !== target.startBaseline) {
      const delta = target.startBaseline
        ? newBaseline - target.startBaseline
        : 0;
      updateTargetFields(target, {
        startValue: target.startValue + delta,
        endValue: target.endValue + delta,
        startBaseline: newBaseline,
      });
    }
  });
}

function updateIfNeeded(
  progress: Progress,
  { newDelta, newValue }: { newDelta: number; newValue: number }
): void {
  if (
    progress.computedDelta !== newDelta ||
    progress.computedValue !== newValue
  ) {
    ProgressStore.update(progress.id, {
      computedDelta: newDelta,
      computedValue: newValue,
    });
    // Also mutate in place
    progress.computedDelta = newDelta;
    progress.computedValue = newValue;
  }
}

export function deleteResult(resultId: string | undefined) {
  if (resultId) {
    ResultStore.delete(resultId);
  }
}

export function updateResultType(resultId: string, type: ResultTargetType) {
  ResultStore.update(resultId, { type: type });
}

export function updateSnooze(resultId: string, newSnooze: Date | null) {
  ResultStore.update(resultId, { snoozedUntil: newSnooze });
}

export function updatePreferredGraphType(
  resultId: string,
  type: PreferredGraphType
) {
  ResultStore.update(resultId, { preferredGraphType: type });
}

export function updatePreferredRateUnit(resultId: string, unit: number) {
  ResultStore.update(resultId, { preferredRateUnit: unit });
}

export function updateComment(resultId: string, comment: string) {
  ResultStore.update(resultId, { comment });
}

export function markComplete(
  resultId: string | undefined,
  newComplete: boolean
) {
  if (resultId) {
    ResultStore.update(resultId, { complete: newComplete });
  }
}

export function updateUnits(resultId: string | undefined, value: string) {
  if (resultId && value !== undefined) {
    ResultStore.update(resultId, { units: value });
  }
}

export function updateCategory(resultId: string | undefined, value: string) {
  if (resultId && value !== undefined) {
    ResultStore.update(resultId, { category: value });
  }
}

export function updateExpectedStep(
  resultId: string | undefined,
  value: number
) {
  if (resultId && value !== undefined) {
    ResultStore.update(resultId, { expectedStep: value });
  }
}

type EditableTargetField =
  | "startDate"
  | "endDate"
  | "startValue"
  | "endValue"
  | "state"
  | "startBaseline";

function updateTargetField<T extends EditableTargetField>(
  target: Target,
  field: T,
  value: Target[T]
) {
  ResultStore.update(target.resultId, {
    [`targets.${target.id}.${field}`]: value,
  });
  target[field] = value;
}

export function updateTargetFields<T extends EditableTargetField>(
  target: Target,
  fieldUpdate: Partial<StoredTarget>
) {
  const updateData: any = {};
  _.keys(fieldUpdate).forEach((field) => {
    updateData[`targets.${target.id}.${field}`] = (fieldUpdate as any)[field];
  });
  ResultStore.update(target.resultId, updateData);
}

export function updateEndValue(target: Target, value: number) {
  updateTargetField(target, "endValue", value);
}

export function updateStartValue(target: Target, value: number) {
  updateTargetField(target, "startValue", value);
}

export function updateStartDate(target: Target, value: Date) {
  updateTargetField(target, "startDate", value);
}

export function updateEndDate(target: Target, value: Date) {
  updateTargetField(target, "endDate", value);
}

export function updateStartBaseline(target: Target, value: number) {
  updateTargetField(target, "startBaseline", value);
}

export function updateTargetState(target: Target, state: TargetState) {
  updateTargetField(target, "state", state);
}

export function computeRate(target: Target, timeUnit: number): number {
  const timeUnits =
    (target.endDate.getTime() - target.startDate.getTime()) / timeUnit;
  return (target.endValue - target.startValue) / timeUnits;
}

export function computeEndFromRate(
  result: Result,
  target: Target,
  timeUnit: number,
  newRate: number
): { endDate: Date; endValue: number } {
  const { startDate, startValue, endDate, endValue } = target;
  if (!newRate) {
    return { endDate, endValue: startValue };
  }
  if (result.type === ResultTargetType.Finite) {
    // Finite value delta
    const valueDelta = endValue - startValue;
    const timeDelta = valueDelta / (newRate / timeUnit);
    return { endDate: addDelta(startDate, timeDelta), endValue };
  } else {
    // Otherwise -> Adjust end value when adjusting rate
    const timeDelta = endDate.getTime() - startDate.getTime();
    const valueDelta = timeDelta * (newRate / timeUnit);
    return { endDate, endValue: startValue + valueDelta };
  }
}

export function computeEndFromEndDate(
  result: Result,
  target: Target,
  newEndDate: Date
): { endDate: Date; endValue: number } {
  const { startDate, startValue, endDate, endValue } = target;
  if (result.type === ResultTargetType.FixedRate) {
    // Fixed rate / ongoing -> Update end value alongside end date
    const newEndValue = interpolateFromDates(
      startDate,
      startValue,
      endDate,
      endValue,
      newEndDate
    );
    return { endDate: newEndDate, endValue: newEndValue };
  } else {
    // Otherwise, just update end date
    return { endDate: newEndDate, endValue };
  }
}

export function computeEndFromEndValue(
  result: Result,
  target: Target,
  newEndValue: number
): { endDate: Date; endValue: number } {
  const { startDate, startValue, endDate, endValue } = target;
  if (
    result.type === ResultTargetType.FixedRate ||
    result.type === ResultTargetType.Ongoing
  ) {
    // Fixed rate / ongoing -> Update end value alongside end date
    const newEndDate = interpolateToDates(
      startValue,
      startDate,
      endValue,
      endDate,
      newEndValue
    );
    return { endDate: newEndDate, endValue: newEndValue };
  } else {
    // Otherwise, just update end date
    return { endDate, endValue: newEndValue };
  }
}

export function migrateLegacyTarget(result: Result) {
  const legacyTarget = result.targets["legacyToMigrate"];
  if (legacyTarget) {
    ResultStore.update(result.id, {
      "targets.legacyMigrated": legacyTarget,
    });
    ResultStore.update(result.id, {
      startDate: firebase.firestore.FieldValue.delete(),
      targetDate: firebase.firestore.FieldValue.delete(),
      startValue: firebase.firestore.FieldValue.delete(),
      targetValue: firebase.firestore.FieldValue.delete(),
    });
  }
}

export function addTarget(result: Result, progress: Progress[]) {
  const targetId = `target-${Date.now()}`;

  const lastTarget = getLatestTarget(result);
  const forwardDays = lastTarget
    ? Math.round(
        (lastTarget.endDate.getTime() - lastTarget.startDate.getTime()) / DAY
      )
    : 30;

  const newTarget = estimateNewTarget(progress, 7, forwardDays);
  ResultStore.update(result.id, {
    [`targets.${targetId}`]: newTarget,
  });
}

export function estimateNewTarget(
  progress: Progress[],
  lookbackDays: number,
  forwardDays: number
): StoredTarget {
  const now = new Date();
  const lookbackStart = addDays(now, -1 * lookbackDays);
  const startDate = addDays(now, -1);
  const endDate = addDays(now, forwardDays);

  endDate.setHours(23);
  startDate.setHours(23);

  if (progress.length <= 1) {
    return {
      startDate,
      startValue: 0,
      endDate,
      endValue: 100,
      state: TargetState.Active,
    };
  }

  progress = _.sortBy(progress, "date");

  const latest = progress[progress.length - 1];

  const { value: lookbackValue, date: lookbackDate } = interpolateProgress(
    progress,
    lookbackStart
  );

  const latestValue = latest.computedValue || 0;

  const endValue = interpolate(
    lookbackDate.getTime(),
    lookbackValue,
    (latest.date.getTime() + Date.now()) / 2,
    latestValue,
    endDate.getTime()
  );

  return {
    startDate,
    startValue: latestValue,
    endDate,
    endValue,
    state: TargetState.Active,
  };
}

export function interpolateProgress(
  progress: Progress[],
  when: Date
): { value: number; date: Date } {
  const firstProgress = progress[0];
  if (firstProgress.date.getTime() > when.getTime()) {
    return {
      value: firstProgress.computedValue || 0,
      date: firstProgress.date,
    };
  }

  for (let i = 0; i < progress.length; i++) {
    const p = progress[i];

    if (p.date.getTime() > when.getTime()) {
      const prev = progress[i - 1];
      const value = interpolateFromDates(
        prev.date,
        prev.computedValue || 0,
        p.date,
        p.computedValue || 0,
        when
      );
      return { value, date: when };
    }
  }

  const latestProgress = progress[progress.length - 1];
  return {
    value: latestProgress.computedValue || 0,
    date: latestProgress.date,
  };
}

export function interpolateFromDates(
  x1: Date,
  y1: number,
  x2: Date,
  y2: number,
  x: Date
) {
  return interpolate(x1.getTime(), y1, x2.getTime(), y2, x.getTime());
}

export function interpolateToDates(
  x1: number,
  y1: Date,
  x2: number,
  y2: Date,
  x: number
): Date {
  return new Date(interpolate(x1, y1.getTime(), x2, y2.getTime(), x));
}

export function interpolate(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x: number
): number {
  if (x2 === x1) {
    return (y1 + y2) / 2;
  }

  const slope = (y2 - y1) / (x2 - x1);
  return y1 + (x - x1) * slope;
}

export function deleteTarget(target: Target) {
  ResultStore.update(target.resultId, {
    [`targets.${target.id}`]: firebase.firestore.FieldValue.delete(),
  });
}
