import { Chunk } from "components/ChunkedBar";

export function makeChunks(
  start: number,
  end: number,
  expected: number,
  actual: number,
  step: number,
  units: string | undefined
): Chunk[] {
  if (actual === end) {
    return [{ ratio: 1.0, value: end, color: "expected-good" }];
  }

  if (end < start) {
    const change = (v: number) => start - v + end;
    [start, end, expected, actual] = [start, end, expected, actual].map(change);
  }
  actual = Math.min(actual, end);
  const projected = Math.min(actual + step, end);

  const chunks: Chunk[] = [];
  const range = end - start;
  // Great: Start - Expected - Actual - Projected - End
  if (actual > expected) {
    chunks.push({
      ratio: (expected - start) / range,
      value: expected,
      color: "expected-good",
    });
    chunks.push({
      ratio: (actual - expected) / range,
      value: actual,
      color: "actual-good",
      importantLabel: true,
    });
    chunks.push({
      ratio: (projected - actual) / range,
      value: projected,
      color: "projected-good",
    });
  } else if (projected > expected) {
    // Close: Start - Actual - Expected - Projected - End
    chunks.push({
      ratio: (actual - start) / range,
      value: actual,
      color: "actual-bad",
      importantLabel: true,
    });
    chunks.push({
      ratio: (expected - actual) / range,
      value: expected,
      color: "expected-close",
    });
    chunks.push({
      ratio: (projected - expected) / range,
      value: projected,
      color: "projected-close",
    });
  } else {
    // Behind: Start - Actual - Projected - Expected - End
    chunks.push({
      ratio: (actual - start) / range,
      value: actual,
      color: "actual-bad",
      importantLabel: true,
    });
    chunks.push({
      ratio: (projected - actual) / range,
      value: projected,
      color: "projected-bad",
    });
    chunks.push({
      ratio: (expected - projected) / range,
      value: expected,
      color: "expected-bad",
    });
  }
  const remainStart = Math.max(actual, expected, projected);
  chunks.push({
    ratio: (end - remainStart) / range,
    value: end,
    color: "remain",
  });
  chunks.forEach((c) => (c.units = units));
  return chunks;
}
