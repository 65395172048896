import React from "react";
import { Dispatch } from "redux";
import { State } from "client/data/state";

interface Props {
  state: State;
  dispatch?: Dispatch;
}

const ScriptPage = ({ state, dispatch }: Props) => {
  return (
    <div>
      <h1>Script page</h1>
      <div>
        <button onClick={() => runScript(state, dispatch)} title="Run script">
          Run script
        </button>
      </div>
    </div>
  );
};
export default ScriptPage;

function runScript(state: State, dispatch?: Dispatch) {
  if (dispatch) {
  }
}
