import React, { useState } from "react";
import { Result } from "client/firebase/models/result";
import { ToggleButton } from "components/ToggleButton";
import { DateSelect } from "components/DateSelect";
import { morningAfter } from "lib/date_utils/arithmetic";

interface Props {
  result: Result;
  onSnoozeChange: (date: Date | null) => void;
}

export function defaultSnooze(): Date {
  return morningAfter(new Date());
}

export function SnoozeControl(props: Props): JSX.Element {
  const { result, onSnoozeChange } = props;
  const [snoozed, setShowPicker] = useState(
    (result.snoozedUntil || 0) > new Date()
  );

  return (
    <div className="snooze-control">
      {snoozed ? (
        <React.Fragment>
          <span>Snoozed until:&nbsp;</span>
          <DateSelect
            isNow={false}
            date={result.snoozedUntil ?? defaultSnooze()}
            onChange={(d) => {
              d.setHours(7);
              onSnoozeChange(d);
            }}
            minDate={new Date()}
          />
          &nbsp;
        </React.Fragment>
      ) : null}
      <ToggleButton
        toggleOffText="X"
        toggleOnText="Snooze"
        onChange={(newSnoozed) => {
          setShowPicker(newSnoozed);
          if (!newSnoozed) {
            onSnoozeChange(null);
          } else {
            onSnoozeChange(defaultSnooze());
          }
        }}
        initialValue={snoozed}
      />
    </div>
  );
}
