export enum TargetState {
  Unspecified = 0,
  Active = 1,
  Aborted = 2,
}

export interface StoredTarget {
  startDate: Date;
  startValue: number;
  endDate: Date;
  endValue: number;
  startBaseline?: number;
  state: TargetState;
}

export interface Target extends StoredTarget {
  resultId: string;
  id: string;
  units: string;
}

export function decreasingTarget(t: Target): boolean {
  return t.endValue < t.startValue;
}
