import firebase from "firebase";

const firebaseui = require("firebaseui");

var authUiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (
      _authResult: any,
      _redirectUrl: any
    ) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
  // Terms of service url.
  tosUrl: "<your-tos-url>",
  // Privacy policy url.
  privacyPolicyUrl: "<your-privacy-policy-url>",
};

export function initAuth() {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const container = document.getElementById("firebaseui-auth-container");
      if (container) {
        container.remove();
      }
    }
  });
  const ui = new firebaseui.auth.AuthUI(firebase.auth());
  const user = firebase.auth().currentUser;
  if (!user) {
    ui.start("#firebaseui-auth-container", {
      ...authUiConfig,
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
      // Other config options...
    });
  }
}

export function isLoggedIn(): boolean {
  return !!firebase.auth().currentUser;
}

export function getUserUID(): string {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw Error("Must be logged in");
  }
  return user.uid;
}
