import React, { useEffect, useRef, useState } from "react";
import ReactAutocomplete from "react-autocomplete";
import _ from "lodash";
import {
  deleteProgress,
  finishTarget,
  parseProgressText,
  Progress,
} from "client/firebase/models/progress";
import { Result } from "client/firebase/models/result";
import { shortFormatWithUnits } from "lib/format";
import { DateSelect } from "components/DateSelect";
import { isMobile } from "lib/device/mobile";

interface Props {
  progress: Progress;
  result?: Result;
  createMode?: boolean;
  results: Result[];
  onUpdateProgress: (id: string, newTitle: string, newDate: Date) => void;
}

function removeResultText(text: string, result: Result | undefined): string {
  if (result && isMobile()) {
    return text.replace(result.text + ": ", "");
  }
  return text;
}

function addResultText(text: string, result: Result | undefined): string {
  if (result && !text.includes(":")) {
    return result.text + ": " + text;
  }
  return text;
}

export default function ProgressRow({
  progress: { id, text, date, created, computedDelta, computedValue },
  result,
  createMode,
  onUpdateProgress,
  results,
}: Props) {
  const [isValid, setValid] = useState(true);
  const [inputText, setInputText] = useState(removeResultText(text, result));
  useEffect(() => {
    setInputText(removeResultText(text, result));
  }, [text, result]);

  const [editing, setEditing] = useState(createMode);
  const [stateDate, setCreatedDate] = useState(date || created);
  const [isNow, setDateNow] = useState(!!createMode);
  const inputEl = useRef(null);

  const allItems = _.uniq(
    results.filter((r) => !r.complete).map((t) => t.text.split(":")[0])
  );

  const matchingItems = allItems.filter(
    (item) => inputText !== "-" && item.indexOf(inputText) > -1
  );
  const menuOpen = editing && matchingItems.length > 0 && inputText.length > 0;

  const brandNewCreate = createMode && text.length === 0;

  const finishEditingIfPossible = (dateOverride?: Date) => {
    finishEditing(inputText, dateOverride);
  };
  const finishEditing = (inputText: string, dateOverride?: Date) => {
    const newText = addResultText(inputText, result);
    const parsed = parseProgressText(newText);
    if (parsed) {
      if (!brandNewCreate && parsed.action !== result?.text) {
        setValid(false);
        return;
      }
      const inputDate = isNow ? new Date() : stateDate;
      onUpdateProgress(id, newText, dateOverride || inputDate);
      // Reset input text if this is a field that started blank
      if (createMode) {
        setInputText(removeResultText(text, result));
        setCreatedDate(date);
        setDateNow(true);
      }
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <div className={`list-item`}>
      <div className={"time"}>
        <DateSelect
          isNow={isNow}
          date={stateDate}
          maxDate={new Date()}
          onChange={(d) => {
            setCreatedDate(d);
            setDateNow(false);
            if (!createMode) {
              finishEditingIfPossible(d);
            }
          }}
          onClose={() => (inputEl.current as any)?.focus()}
        />
      </div>
      <div className="title">
        <ReactAutocomplete
          getItemValue={(item) => item}
          items={result ? [] : matchingItems}
          open={menuOpen}
          renderItem={(item, highlighted) => (
            <div
              key={item}
              style={{ backgroundColor: highlighted ? "#eee" : "transparent" }}
            >
              {item}
            </div>
          )}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
            setValid(true);
          }}
          onSelect={(value) => {
            setInputText(value + ": +");
            setValid(true);
          }}
          menuStyle={{
            borderRadius: "3px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255, 0.95)",
            padding: "2px 4px",
            fontSize: "90%",
            position: "fixed",
            overflow: "auto",
            maxHeight: "50%",
            zIndex: 2,
          }}
          ref={inputEl}
          wrapperStyle={{ display: "inline" }}
          inputProps={{
            placeholder: "Making progress: +100",
            autoFocus: createMode,
            type: "text",
            onMouseDown: () => {
              setEditing(true);
            },
            onBlur: () => {
              setEditing(false);
              finishEditingIfPossible();
            },
            style: {
              color: isValid ? "" : "red",
            },
            onKeyDown: (e) => {
              if (menuOpen) {
                return;
              }
              if (e.which === 13) {
                if (
                  !createMode &&
                  inputText.trim().toLowerCase() === "delete"
                ) {
                  setInputText("delete? y to confirm: ");
                  return;
                } else if (
                  inputText.trim().toLowerCase() === "delete? y to confirm: y"
                ) {
                  deleteProgress(id);
                  return;
                }

                const newText = addResultText(inputText, result);
                if (newText.indexOf(":") === -1) {
                  setInputText(newText.trimRight() + ": ");
                } else {
                  const splits = newText.split(":");
                  if (
                    splits.length === 2 &&
                    splits[1].trim().toLowerCase() === "delete"
                  ) {
                    setInputText("delete? y to confirm: ");
                  } else if (
                    splits.length === 2 &&
                    splits[1].trim().toLowerCase() === "done" &&
                    !!result
                  ) {
                    finishTarget(result, finishEditing);
                    return;
                  } else {
                    finishEditingIfPossible();
                  }
                }
              }
            },
          }}
        />
      </div>
      <div className="computed" title={`${computedValue}`}>
        {computedDelta !== undefined
          ? `${computedDelta > 0 ? "+" : ""}${shortFormatWithUnits(
              computedDelta,
              result?.units
            )}`
          : null}
        <br className="mobile-only" />{" "}
        {computedValue
          ? `=${shortFormatWithUnits(computedValue, result?.units)}`
          : null}
      </div>
    </div>
  );
}
