import { Progress } from "client/firebase/models/progress";
import { Result } from "client/firebase/models/result";

export interface State {
  progress: ProgressState;
  result: ResultState;
  error?: string;
}

// Progress State
export interface ProgressState {
  items: Progress[];
  loaded: boolean;
}
const emptyProgressState: ProgressState = {
  items: [],
  loaded: false,
};

// Result state
export interface ResultState {
  items: Result[];
  loaded: boolean;
}
const emptyResultState: ResultState = {
  items: [],
  loaded: false,
};

// Empty state
export const emptyState: State = {
  progress: emptyProgressState,
  result: emptyResultState,
};
