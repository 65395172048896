import { emptyState, State } from "client/data/state";
import { Action } from "client/data/actions/actions";
import { progressReducer } from "client/data/reducers/progress";
import { resultReducer } from "client/data/reducers/result";

export const reducer = (state: State | undefined, action: Action) => {
  if (state === undefined) {
    state = emptyState;
  }
  return {
    progress: progressReducer(state.progress, action),
    result: resultReducer(state.result, action),
  };
};
