import React, { useState } from "react";
import classNames from "classnames";

interface Props {
  initialValue: string;
  onSubmit: (value: string) => void;
  validate?: (value: string) => boolean;
  className?: string;
  controlled?: boolean;
  placeholder?: string;
}

enum Mode {
  UNKNOWN = 0,
  SAVED = 1,
  EDITING = 2,
  EDITING_INVALID = 3,
  INVALID = 4,
}

export const MagicInput = (props: Props) => {
  const {
    className,
    initialValue,
    onSubmit,
    validate,
    controlled,
    placeholder,
  } = props;

  const doValidate = validate ?? ((_) => true);

  const [mode, setMode] = useState(Mode.SAVED);
  const [inputValue, setInputValue] = useState(initialValue);

  const maybeSubmit = (value: string) => {
    if (doValidate(value)) {
      setMode(Mode.SAVED);
      onSubmit(value);
    } else {
      setMode(Mode.INVALID);
    }
  };

  const modeClass = `magic-input magic-input-${Mode[mode].toLowerCase()}`;

  return (
    <input
      className={classNames(className, modeClass)}
      value={
        controlled && mode === Mode.SAVED
          ? initialValue
          : inputValue ?? initialValue
      }
      onChange={(e) => {
        const v = e.target.value;
        setMode(doValidate(v) ? Mode.EDITING : Mode.EDITING_INVALID);
        setInputValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.which === 13) {
          maybeSubmit(inputValue);
        }
      }}
      onBlur={() => {
        maybeSubmit(inputValue);
      }}
      placeholder={placeholder}
    />
  );
};
