import { Progress } from "client/firebase/models/progress";
import { Result } from "client/firebase/models/result";

export type Action = OverwriteProgressAction | OverwriteResultAction;

interface OverwriteProgressAction {
  type: ActionType.OverwriteProgress;
  progress: Progress[];
}

interface OverwriteResultAction {
  type: ActionType.OverwriteResults;
  result: Result[];
}

export enum ActionType {
  OverwriteProgress = "OVERWRITE_PROGRESS",
  AddResult = "ADD_RESULT",
  OverwriteResults = "OVERWRITE_RESULTS",
}
