export interface Color {
  hex: string;
}

const COLOR = {
  Blue: {
    hex: "#1f77b4",
  },
  Orange: {
    hex: "#dbbc89",
  },
  LightBlue: {
    hex: "#a4cbe0",
  },
  Green: {
    hex: "#cce0a4",
  },
};
export default COLOR;
