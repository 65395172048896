import React, { useState } from "react";
import classNames from "classnames";

interface Props {
  label: string;
  onConfirm: () => void;
  className?: string;
  skipConfirm?: boolean;
  isGood?: boolean;
}

export const ConfirmButton = (props: Props) => {
  const { onConfirm, label, className, skipConfirm, isGood } = props;
  const [confirming, setConfirming] = useState(false);

  const confirmClass = confirming
    ? isGood
      ? "button-confirming-good"
      : "button-confirming"
    : "";

  const text = confirming ? "Sure?" : label;
  return (
    <button
      className={classNames(className, confirmClass)}
      onBlur={() => setConfirming(false)}
      onClick={() => {
        if (skipConfirm || confirming) {
          onConfirm();
          setConfirming(false);
        } else {
          setConfirming(true);
        }
      }}
    >
      {text}
    </button>
  );
};
