import { Size } from "components/graphboard/GraphboardView";

export function getDimensions(): Size {
  if (window.innerWidth <= 400) {
    const width = 260;
    return { width, height: (width * 2) / 3 };
  } else {
    const width = Math.min(window.innerWidth - 200, 900);

    const height = Math.min(width / 2, 360);
    return { width, height };
  }
}
