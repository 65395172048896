import React, { useState } from "react";
import { MagicInput } from "components/MagicInput";
import { updateCategory } from "client/firebase/models/result";
import { Breadcrumbs, splitCrumbs } from "components/Breadcrumbs";
import { ConfirmButton } from "components/ConfirmButton";

interface Props {
  resultId: string;
  category: string;
}

export function CategoryDisplay(props: Props): JSX.Element {
  const { category, resultId } = props;
  const [editing, setEditing] = useState(!category);

  if (!category || editing) {
    return (
      <MagicInput
        className="category-input"
        initialValue={category ?? ""}
        onSubmit={(v) => {
          updateCategory(resultId, v.trim());
          setEditing(false);
        }}
      />
    );
  } else {
    return (
      <span>
        <Breadcrumbs crumbs={splitCrumbs("/category", category)} />
        <ConfirmButton
          label="Edit"
          onConfirm={() => setEditing(true)}
          skipConfirm
        />
      </span>
    );
  }
}
