/* eslint-disable */
// @ts-nocheck
const parser =
  /*
   * Generated by PEG.js 0.10.0.
   *
   * http://pegjs.org/
   */
  (function () {
    "use strict";

    function peg$subclass(child, parent) {
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
    }

    function peg$SyntaxError(message, expected, found, location) {
      this.message = message;
      this.expected = expected;
      this.found = found;
      this.location = location;
      this.name = "SyntaxError";

      if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(this, peg$SyntaxError);
      }
    }

    peg$subclass(peg$SyntaxError, Error);

    peg$SyntaxError.buildMessage = function (expected, found) {
      var DESCRIBE_EXPECTATION_FNS = {
        literal: function (expectation) {
          return '"' + literalEscape(expectation.text) + '"';
        },

        class: function (expectation) {
          var escapedParts = "",
            i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts +=
              expectation.parts[i] instanceof Array
                ? classEscape(expectation.parts[i][0]) +
                  "-" +
                  classEscape(expectation.parts[i][1])
                : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function (expectation) {
          return "any character";
        },

        end: function (expectation) {
          return "end of input";
        },

        other: function (expectation) {
          return expectation.description;
        },
      };

      function hex(ch) {
        return ch.charCodeAt(0).toString(16).toUpperCase();
      }

      function literalEscape(s) {
        return s
          .replace(/\\/g, "\\\\")
          .replace(/"/g, '\\"')
          .replace(/\0/g, "\\0")
          .replace(/\t/g, "\\t")
          .replace(/\n/g, "\\n")
          .replace(/\r/g, "\\r")
          .replace(/[\x00-\x0F]/g, function (ch) {
            return "\\x0" + hex(ch);
          })
          .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
            return "\\x" + hex(ch);
          });
      }

      function classEscape(s) {
        return s
          .replace(/\\/g, "\\\\")
          .replace(/\]/g, "\\]")
          .replace(/\^/g, "\\^")
          .replace(/-/g, "\\-")
          .replace(/\0/g, "\\0")
          .replace(/\t/g, "\\t")
          .replace(/\n/g, "\\n")
          .replace(/\r/g, "\\r")
          .replace(/[\x00-\x0F]/g, function (ch) {
            return "\\x0" + hex(ch);
          })
          .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
            return "\\x" + hex(ch);
          });
      }

      function describeExpectation(expectation) {
        return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
      }

      function describeExpected(expected) {
        var descriptions = new Array(expected.length),
          i,
          j;

        for (i = 0; i < expected.length; i++) {
          descriptions[i] = describeExpectation(expected[i]);
        }

        descriptions.sort();

        if (descriptions.length > 0) {
          for (i = 1, j = 1; i < descriptions.length; i++) {
            if (descriptions[i - 1] !== descriptions[i]) {
              descriptions[j] = descriptions[i];
              j++;
            }
          }
          descriptions.length = j;
        }

        switch (descriptions.length) {
          case 1:
            return descriptions[0];

          case 2:
            return descriptions[0] + " or " + descriptions[1];

          default:
            return (
              descriptions.slice(0, -1).join(", ") +
              ", or " +
              descriptions[descriptions.length - 1]
            );
        }
      }

      function describeFound(found) {
        return found ? '"' + literalEscape(found) + '"' : "end of input";
      }

      return (
        "Expected " +
        describeExpected(expected) +
        " but " +
        describeFound(found) +
        " found."
      );
    };

    function peg$parse(input, options) {
      options = options !== void 0 ? options : {};

      var peg$FAILED = {},
        peg$startRuleFunctions = { Input: peg$parseInput },
        peg$startRuleFunction = peg$parseInput,
        peg$c0 = peg$otherExpectation("delete operation"),
        peg$c1 = "delete",
        peg$c2 = peg$literalExpectation("delete", false),
        peg$c3 = "Delete",
        peg$c4 = peg$literalExpectation("Delete", false),
        peg$c5 = function () {
          return { action: "DeleteRequest" };
        },
        peg$c6 = /^[Yy]/,
        peg$c7 = peg$classExpectation(["Y", "y"], false, false),
        peg$c8 = function () {
          return { action: "DeleteConfirm" };
        },
        peg$c9 = "delete? y to confirm:",
        peg$c10 = peg$literalExpectation("delete? y to confirm:", false),
        peg$c11 = function (activity, progress, comment) {
          return {
            action: "RecordProgress",
            activity: {
              activity: activity,
              progress,
              comment,
            },
          };
        },
        peg$c12 = ":",
        peg$c13 = peg$literalExpectation(":", false),
        peg$c14 = function (activity) {
          return activity;
        },
        peg$c15 = peg$otherExpectation("activity name"),
        peg$c16 = "//",
        peg$c17 = peg$literalExpectation("//", false),
        peg$c18 = function (value) {
          return value.trim();
        },
        peg$c19 = peg$otherExpectation("comment text"),
        peg$c20 = peg$otherExpectation("progress value"),
        peg$c21 = "+",
        peg$c22 = peg$literalExpectation("+", false),
        peg$c23 = function (v) {
          return { type: "relative", value: v };
        },
        peg$c24 = "-",
        peg$c25 = peg$literalExpectation("-", false),
        peg$c26 = function (v) {
          return { type: "relative", value: -v };
        },
        peg$c27 = function (v) {
          return { type: "absolute", value: v };
        },
        peg$c28 = peg$otherExpectation("positive integer"),
        peg$c29 = /^[1-9]/,
        peg$c30 = peg$classExpectation([["1", "9"]], false, false),
        peg$c31 = /^[0-9]/,
        peg$c32 = peg$classExpectation([["0", "9"]], false, false),
        peg$c33 = function (digits) {
          return parseInt(digits.flat().join(""), 10);
        },
        peg$c34 = peg$otherExpectation("nonnegative integer"),
        peg$c35 = "0",
        peg$c36 = peg$literalExpectation("0", false),
        peg$c37 = function () {
          return 0;
        },
        peg$c38 = peg$otherExpectation("integer"),
        peg$c39 = function (int) {
          return -int;
        },
        peg$c40 = peg$otherExpectation("decimal suffix"),
        peg$c41 = ".",
        peg$c42 = peg$literalExpectation(".", false),
        peg$c43 = function (digits) {
          return parseFloat("0." + digits.flat().join(""), 10);
        },
        peg$c44 = peg$otherExpectation("nonnegative decimal"),
        peg$c45 = function (int, decimal) {
          return int + (decimal || 0);
        },
        peg$c46 = peg$otherExpectation("decimal"),
        peg$c47 = function (v) {
          return -v;
        },
        peg$c48 = peg$otherExpectation("text"),
        peg$c49 = /^[^:\/]/,
        peg$c50 = peg$classExpectation([":", "/"], true, false),
        peg$c51 = function (letters) {
          return letters.join("");
        },
        peg$c52 = peg$otherExpectation("whitespace"),
        peg$c53 = /^[ \t\n\r]/,
        peg$c54 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false),
        peg$currPos = 0,
        peg$savedPos = 0,
        peg$posDetailsCache = [{ line: 1, column: 1 }],
        peg$maxFailPos = 0,
        peg$maxFailExpected = [],
        peg$silentFails = 0,
        peg$result;

      if ("startRule" in options) {
        if (!(options.startRule in peg$startRuleFunctions)) {
          throw new Error(
            "Can't start parsing from rule \"" + options.startRule + '".'
          );
        }

        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
      }

      function text() {
        return input.substring(peg$savedPos, peg$currPos);
      }

      function location() {
        return peg$computeLocation(peg$savedPos, peg$currPos);
      }

      function expected(description, location) {
        location =
          location !== void 0
            ? location
            : peg$computeLocation(peg$savedPos, peg$currPos);

        throw peg$buildStructuredError(
          [peg$otherExpectation(description)],
          input.substring(peg$savedPos, peg$currPos),
          location
        );
      }

      function error(message, location) {
        location =
          location !== void 0
            ? location
            : peg$computeLocation(peg$savedPos, peg$currPos);

        throw peg$buildSimpleError(message, location);
      }

      function peg$literalExpectation(text, ignoreCase) {
        return { type: "literal", text: text, ignoreCase: ignoreCase };
      }

      function peg$classExpectation(parts, inverted, ignoreCase) {
        return {
          type: "class",
          parts: parts,
          inverted: inverted,
          ignoreCase: ignoreCase,
        };
      }

      function peg$anyExpectation() {
        return { type: "any" };
      }

      function peg$endExpectation() {
        return { type: "end" };
      }

      function peg$otherExpectation(description) {
        return { type: "other", description: description };
      }

      function peg$computePosDetails(pos) {
        var details = peg$posDetailsCache[pos],
          p;

        if (details) {
          return details;
        } else {
          p = pos - 1;
          while (!peg$posDetailsCache[p]) {
            p--;
          }

          details = peg$posDetailsCache[p];
          details = {
            line: details.line,
            column: details.column,
          };

          while (p < pos) {
            if (input.charCodeAt(p) === 10) {
              details.line++;
              details.column = 1;
            } else {
              details.column++;
            }

            p++;
          }

          peg$posDetailsCache[pos] = details;
          return details;
        }
      }

      function peg$computeLocation(startPos, endPos) {
        var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails = peg$computePosDetails(endPos);

        return {
          start: {
            offset: startPos,
            line: startPosDetails.line,
            column: startPosDetails.column,
          },
          end: {
            offset: endPos,
            line: endPosDetails.line,
            column: endPosDetails.column,
          },
        };
      }

      function peg$fail(expected) {
        if (peg$currPos < peg$maxFailPos) {
          return;
        }

        if (peg$currPos > peg$maxFailPos) {
          peg$maxFailPos = peg$currPos;
          peg$maxFailExpected = [];
        }

        peg$maxFailExpected.push(expected);
      }

      function peg$buildSimpleError(message, location) {
        return new peg$SyntaxError(message, null, null, location);
      }

      function peg$buildStructuredError(expected, found, location) {
        return new peg$SyntaxError(
          peg$SyntaxError.buildMessage(expected, found),
          expected,
          found,
          location
        );
      }

      function peg$parseInput() {
        var s0;

        s0 = peg$parseDeleteInput();
        if (s0 === peg$FAILED) {
          s0 = peg$parseProgressInput();
        }

        return s0;
      }

      function peg$parseDeleteInput() {
        var s0, s1;

        peg$silentFails++;
        s0 = peg$parseDeleteConfirm();
        if (s0 === peg$FAILED) {
          s0 = peg$parseDeleteRequest();
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c0);
          }
        }

        return s0;
      }

      function peg$parseDeleteRequest() {
        var s0, s1;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 6) === peg$c1) {
          s1 = peg$c1;
          peg$currPos += 6;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c2);
          }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c3) {
            s1 = peg$c3;
            peg$currPos += 6;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c4);
            }
          }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c5();
        }
        s0 = s1;

        return s0;
      }

      function peg$parseDeleteConfirm() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parseDeleteConfirmPrompt();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (peg$c6.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c7);
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c8();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseDeleteConfirmPrompt() {
        var s0;

        if (input.substr(peg$currPos, 21) === peg$c9) {
          s0 = peg$c9;
          peg$currPos += 21;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c10);
          }
        }

        return s0;
      }

      function peg$parseProgressInput() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parseActivityPrefix();
        if (s1 === peg$FAILED) {
          s1 = null;
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseProgressValue();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseComment();
                if (s5 === peg$FAILED) {
                  s5 = null;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c11(s1, s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseActivityPrefix() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = peg$parseActivityName();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 58) {
            s2 = peg$c12;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c13);
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c14(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseActivityName() {
        var s0, s1;

        peg$silentFails++;
        s0 = peg$parseTextString();
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c15);
          }
        }

        return s0;
      }

      function peg$parseComment() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c16) {
          s1 = peg$c16;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c17);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseCommentText();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c18(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }

        return s0;
      }

      function peg$parseCommentText() {
        var s0, s1;

        peg$silentFails++;
        s0 = peg$parseTextString();
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c19);
          }
        }

        return s0;
      }

      function peg$parseProgressValue() {
        var s0, s1;

        peg$silentFails++;
        s0 = peg$parseDeltaValue();
        if (s0 === peg$FAILED) {
          s0 = peg$parseAbsoluteValue();
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c20);
          }
        }

        return s0;
      }

      function peg$parseDeltaValue() {
        var s0, s1, s2;

        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 43) {
          s1 = peg$c21;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c22);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseNonnegNumber();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c23(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 45) {
            s1 = peg$c24;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c25);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parseNonnegNumber();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c26(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }

        return s0;
      }

      function peg$parseAbsoluteValue() {
        var s0, s1;

        s0 = peg$currPos;
        s1 = peg$parseInt();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c27(s1);
        }
        s0 = s1;

        return s0;
      }

      function peg$parsePositiveInt() {
        var s0, s1, s2, s3, s4;

        peg$silentFails++;
        s0 = peg$currPos;
        s1 = peg$currPos;
        if (peg$c29.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c30);
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = [];
          if (peg$c31.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c32);
            }
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (peg$c31.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c32);
              }
            }
          }
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c33(s1);
        }
        s0 = s1;
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c28);
          }
        }

        return s0;
      }

      function peg$parseNonnegInt() {
        var s0, s1;

        peg$silentFails++;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 48) {
          s1 = peg$c35;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c36);
          }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c37();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$parsePositiveInt();
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c34);
          }
        }

        return s0;
      }

      function peg$parseInt() {
        var s0, s1, s2;

        peg$silentFails++;
        s0 = peg$parseNonnegInt();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 45) {
            s1 = peg$c24;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c25);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parsePositiveInt();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c39(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c38);
          }
        }

        return s0;
      }

      function peg$parseDecimalSuffix() {
        var s0, s1, s2, s3;

        peg$silentFails++;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 46) {
          s1 = peg$c41;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c42);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = [];
          if (peg$c31.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c32);
            }
          }
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            if (peg$c31.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c32);
              }
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c43(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c40);
          }
        }

        return s0;
      }

      function peg$parseNonnegDecimal() {
        var s0, s1, s2;

        peg$silentFails++;
        s0 = peg$currPos;
        s1 = peg$parseNonnegInt();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseDecimalSuffix();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c45(s1, s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c44);
          }
        }

        return s0;
      }

      function peg$parseDecimal() {
        var s0, s1, s2;

        peg$silentFails++;
        s0 = peg$parseNonnegDecimal();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 45) {
            s1 = peg$c24;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c25);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parseNonnegDecimal();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c47(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c46);
          }
        }

        return s0;
      }

      function peg$parseNonnegNumber() {
        var s0;

        s0 = peg$parseNonnegDecimal();
        if (s0 === peg$FAILED) {
          s0 = peg$parseNonnegInt();
        }

        return s0;
      }

      function peg$parseNumber() {
        var s0;

        s0 = peg$parseDecimal();
        if (s0 === peg$FAILED) {
          s0 = peg$parseInt();
        }

        return s0;
      }

      function peg$parseTextString() {
        var s0, s1, s2;

        peg$silentFails++;
        s0 = peg$currPos;
        s1 = [];
        if (peg$c49.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c50);
          }
        }
        if (s2 !== peg$FAILED) {
          while (s2 !== peg$FAILED) {
            s1.push(s2);
            if (peg$c49.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c50);
              }
            }
          }
        } else {
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c51(s1);
        }
        s0 = s1;
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c48);
          }
        }

        return s0;
      }

      function peg$parse_() {
        var s0, s1;

        peg$silentFails++;
        s0 = [];
        if (peg$c53.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c54);
          }
        }
        while (s1 !== peg$FAILED) {
          s0.push(s1);
          if (peg$c53.test(input.charAt(peg$currPos))) {
            s1 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$c54);
            }
          }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$c52);
          }
        }

        return s0;
      }

      peg$result = peg$startRuleFunction();

      if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
      } else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
          peg$fail(peg$endExpectation());
        }

        throw peg$buildStructuredError(
          peg$maxFailExpected,
          peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
          peg$maxFailPos < input.length
            ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
            : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
        );
      }
    }

    return {
      SyntaxError: peg$SyntaxError,
      parse: peg$parse,
    };
  })();
export const parse = parser.parse;
