import React from "react";
import ProgressList from "components/ProgressList";
import { emptyState, State } from "client/data/state";
import ProgressCreator from "components/ProgressCreator";
import { Link, Route, Switch } from "react-router-dom";
import ScriptPage from "components/ScriptsPage";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ResultListPage from "components/ResultListPage";
import {
  overwriteProgressFromServer,
  subscribeToProgress,
} from "client/data/actions/progress";
import {
  overwriteResultsFromServer,
  subscribeToResults,
} from "client/data/actions/result";
import ResultPage from "components/ResultPage";
import { CategoryPage } from "components/CategoryPage";

interface Props {
  error?: string;
  dispatch?: Dispatch;
  state?: State;
}

export function PureApp({ error, state, dispatch }: Props) {
  if (error) {
    return (
      <div className="page lists-show">
        <div className="wrapper-message">
          <span className="icon-face-sad" />
          <div className="title-message">Oh no!</div>
          <div className="subtitle-message">Something went wrong...</div>
        </div>
      </div>
    );
  }
  return (
    <div className="page lists-show">
      <nav>
        <h1 className="title-page">
          <span className="title-wrapper">
            <Link to="/">Dagbok</Link>
          </span>
        </h1>
        <div className="nav-tabs" />
      </nav>
      <div className="page-body">
        <Switch>
          <Route path="/create">
            <ProgressCreator />
            <div className="wrapper-message">(Page for creating only)</div>
          </Route>
          <Route path="/result/:resultId">
            <ResultPage />
          </Route>
          <Route path="/category/:category">
            <CategoryPage />
          </Route>
          <Route path="/scripts">
            <ScriptPage state={state || emptyState} dispatch={dispatch} />
          </Route>
          <Route path="/log">
            <ProgressCreator />
            <ProgressList />
          </Route>
          <Route path={["/results", "/"]}>
            <ProgressCreator />
            <hr className="no-top-margin" />
            <ResultListPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default connect(
  (state: State) => ({
    error: state.error,
    fullState: state,
  }),
  (dispatch) => {
    overwriteProgressFromServer(dispatch);
    subscribeToProgress(dispatch);
    overwriteResultsFromServer(dispatch);
    subscribeToResults(dispatch);
    return {
      dispatch,
    };
  }
)(PureApp);
