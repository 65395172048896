import firebase from "firebase";

import "firebase/auth";
import "firebase/firestore";
import { initAuth } from "client/firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBgbUGT0NCBojE9e8awN9x1DJznXbI1Xjk",
  authDomain: "cratest-firebase.firebaseapp.com",
  databaseURL: "https://cratest-firebase.firebaseio.com",
  projectId: "cratest-firebase",
  storageBucket: "cratest-firebase.appspot.com",
  messagingSenderId: "148929092833",
  appId: "1:148929092833:web:67d5b648d57cc0d7b5aba0",
};

export function initFirebase() {
  firebase.initializeApp(firebaseConfig);
  initAuth();
}
