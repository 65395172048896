import React, { useState } from "react";
import { ConfirmButton } from "components/ConfirmButton";

interface Props {
  toggleOffText: string;
  toggleOnText: string;
  onChange: (value: boolean) => void;
  initialValue: boolean;
  requireConfirm?: boolean;
  isGood?: boolean;
  className?: string;
}

export const ToggleButton = ({
  toggleOffText,
  toggleOnText,
  onChange,
  initialValue,
  requireConfirm,
  isGood,
  className,
}: Props) => {
  const [inputVal, setInputVal] = useState(initialValue);
  return (
    <ConfirmButton
      className={className}
      onConfirm={() => {
        const newVal = !inputVal;
        setInputVal(newVal);
        onChange(newVal);
      }}
      skipConfirm={!requireConfirm}
      label={inputVal ? toggleOffText : toggleOnText}
      isGood={isGood}
    />
  );
};
