import React from "react";
import { computeDateVal, Result } from "client/firebase/models/result";
import { addMonths, minDate } from "lib/date_utils/arithmetic";
import { Target } from "client/firebase/models/target";
import TargetRateIndicator from "components/TargetRateIndicator";
import { makeChunks } from "components/Chunk";
import { formatWithUnits, shortFormatWithUnits } from "lib/format";
import { computeFormattedTimeDelta } from "client/dagbok/analysis/steps";
import { Chunk, ChunkedBar } from "components/ChunkedBar";
import _ from "lodash";

interface Props {
  result: Result;
  target?: Target;
  current?: number;
  complete?: boolean;
  showRate?: boolean;
  onlyImportantLabels?: boolean;
  showBar?: boolean;
}

const TargetIndicator = ({
  result,
  target,
  current,
  complete,
  showRate,
  onlyImportantLabels,
}: Props) => {
  if (
    !target ||
    result.complete ||
    current === undefined
    // target.startDate.getTime() > Date.now()
  ) {
    const c: Chunk = {
      ratio: 0,
      value: current || 0,
      color: "actual-no-goal",
    };
    const total: Chunk = {
      ratio: 1.0,
      value: current || 0,
      formattedValue: !!target ? target?.endValue.toString() : `???`,
      color: complete ? "expected-good" : "remain",
    };
    let text = "no goal";
    if (result.complete) {
      text = "Done!";
    } else if (target && target.startDate.getTime() > Date.now()) {
      text = "not started";
    } else if (_.size(result.targets) > 0 && !target) {
      text = "aborted";
    }
    return (
      <div className="result-indicator-wrapper">
        <span className={`time-delta`}>{text}</span>
        <ChunkedBar chunks={[c, total]} />
      </div>
    );
  }
  const now = new Date();
  const barEndDate = minDate(addMonths(now, 1), target.endDate);
  const nowExpected = computeDateVal(target, new Date());
  const barEndExpected = computeDateVal(target, barEndDate);
  const step = Math.min(
    result.expectedStep,
    Math.abs(target.endValue - current)
  );

  const chunks = makeChunks(
    target.startValue,
    barEndExpected,
    nowExpected,
    current,
    step,
    result.units
  );

  const { timeDeltaClass, timeDelta, stepInfo } = computeFormattedTimeDelta(
    target,
    current,
    step
  );

  // INCreasing versions
  let startInc = target.startValue;
  let endInc = target.endValue;
  let nowExpectedInc = nowExpected;
  let currentInc = current;
  if (target.startValue > barEndExpected) {
    const change = (v: number) => startInc - v + endInc;
    [startInc, endInc, nowExpectedInc, currentInc] = [
      startInc,
      endInc,
      nowExpected,
      current,
    ].map(change);
  }

  const projectedInc = currentInc + Math.abs(step);
  let comparisonClass = "result-comparison-good";
  if (projectedInc < nowExpectedInc) {
    comparisonClass = "result-comparison-bad";
  } else if (currentInc < nowExpectedInc) {
    comparisonClass = "result-comparison-close";
  }

  return (
    <div className="result-indicator-wrapper">
      <span className={`${timeDeltaClass} time-delta no-mobile`}>
        {timeDelta}
      </span>
      <div className="result-indicator-wrapper-inner">
        <ChunkedBar chunks={chunks} onlyImportantLabels={onlyImportantLabels} />
        <div className="indicator-footer">
          <div className="indicator-details">
            {shortFormatWithUnits(currentInc, result.units)} ➡{" "}
            {shortFormatWithUnits(projectedInc, result.units)}{" "}
            <br className="mobile-only" />
            <span className={comparisonClass}>
              (vs. {shortFormatWithUnits(nowExpectedInc, result.units)})
            </span>{" "}
            / {formatWithUnits(endInc, result.units)}
          </div>
          <div className="step-info">
            {stepInfo}
            {(result.snoozedUntil || 0) > now ? " (snoozed)" : ""}
          </div>
        </div>
      </div>
      {showRate ? (
        <div className="mobile-only mobile-rate-indicator">
          <TargetRateIndicator result={result} target={target} simple />
        </div>
      ) : null}
    </div>
  );
};

export default TargetIndicator;
