import React, { useState } from "react";
import { endOfDay } from "lib/date_utils/arithmetic";
import ReactDatePicker from "react-datepicker";
import { isMobile } from "lib/device/mobile";
import "components/date-select.css";

interface Props {
  date: Date;
  isNow: boolean;
  onChange: (date: Date) => void;
  onClose?: () => void;
  maxDate?: Date;
  minDate?: Date;
}

const useBasicInput = false;

export function DateSelect(props: Props): JSX.Element {
  const { isNow, date, onChange, onClose, maxDate, minDate } = props;
  const dateHasTime = date.getTime() !== endOfDay(date).getTime();
  const [withTime, setWithTime] = useState(!isNow && dateHasTime);
  return (
    <ReactDatePicker
      disabledKeyboardNavigation
      selected={date}
      onChange={(date: Date) => {
        const newDate = withTime ? date : endOfDay(date);
        onChange(newDate);
      }}
      onCalendarClose={onClose}
      customInput={
        useBasicInput ? null : (
          <DateSelectInput
            date={date}
            isNow={isNow}
            dateHasTime={isNow || dateHasTime}
            setWithTime={setWithTime}
          />
        )
      }
      dateFormat="MM/dd/yy"
      maxDate={maxDate}
      minDate={minDate}
      timeIntervals={60}
      showTimeInput={withTime}
    />
  );
}

interface InputProps {
  date: Date;
  isNow: boolean;
  onClick?: () => void;
  setWithTime: (b: boolean) => void;
  dateHasTime: boolean;
}

const DateSelectInput = ({
  date,
  onClick,
  isNow,
  setWithTime,
  dateHasTime,
}: InputProps) => {
  const dateWithTime = date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const now = new Date();
  const lastYear = date.getFullYear() !== now.getFullYear();

  return (
    <button
      className="date-select-input"
      onClick={(e) => {
        if (e.shiftKey) {
          setWithTime(!dateHasTime);
        }
        if (onClick) {
          onClick();
        }
      }}
      title={isNow ? "now" : dateWithTime}
    >
      {isNow ? (
        <span>now</span>
      ) : (
        date.toLocaleString("en-US", {
          month: "numeric",
          day: "numeric",
          weekday: lastYear || isMobile() ? "narrow" : "short",
          year: lastYear ? "2-digit" : undefined,
        })
      )}
    </button>
  );
};
