import React, { useState } from "react";
import { ResultTargetType } from "client/firebase/models/result";
import { ConfirmButton } from "components/ConfirmButton";

interface Props {
  className?: string;
  initialType: ResultTargetType;
  onNewType: (newType: ResultTargetType) => void;
}

export function TargetTypeToggle(props: Props): JSX.Element {
  const { initialType, className, onNewType } = props;
  const [type, setType] = useState(initialType);
  const currentInfo = typeInfo[type];

  return (
    <ConfirmButton
      className={className}
      onConfirm={() => {
        const nextType = currentInfo.nextType;
        setType(nextType);
        onNewType(nextType);
      }}
      skipConfirm
      label={currentInfo.label}
    />
  );
}

// Config, setup
const typeOrder: { type: ResultTargetType; label: string }[] = [
  { type: ResultTargetType.Ongoing, label: "Ongoing" },
  { type: ResultTargetType.Finite, label: "Finite Units" },
  { type: ResultTargetType.FixedRate, label: "Fixed Rate" },
  { type: ResultTargetType.FixedTime, label: "Fixed Duration" },
];

interface TypeInfo {
  label: string;
  nextType: ResultTargetType;
}

const typeInfo: { [type: string]: TypeInfo } = {};
typeOrder.forEach(({ type, label }, i) => {
  const nextType = typeOrder[(i + 1) % typeOrder.length].type;
  typeInfo[type] = {
    label: label,
    nextType,
  };
});
