import React from "react";
import { Link } from "react-router-dom";
import "./crumbs.css";

export function splitCrumbs(baseUrl: string, path: string): CrumbProps[] {
  let url = "/category";
  return path.split("/").map((component) => {
    url = `${url}/${component}`;
    return {
      text: component,
      url,
    };
  });
}
interface Props {
  crumbs: CrumbProps[];
  lastCurrent?: boolean;
}

export function Breadcrumbs({ lastCurrent, crumbs }: Props): JSX.Element {
  const elements: JSX.Element[] = [];
  crumbs.forEach((c, i) => {
    if (i > 0) {
      elements.push(<span className="crumb-divider">/</span>);
    }
    elements.push(
      <Crumb current={lastCurrent && i === crumbs.length - 1} {...c} />
    );
  });

  return <div className="breadcrumbs">{elements}</div>;
}

export interface CrumbProps {
  text: string;
  url: string;
  current?: boolean;
}

export function Crumb({ current, text, url }: CrumbProps): JSX.Element {
  return (
    <div className="crumb">
      {current ? <span>{text}</span> : <Link to={url}>{text}</Link>}
    </div>
  );
}
