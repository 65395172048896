import * as time from "lib/date_utils/time";
import numeral from "numeral";

export function simpleTime(d: Date) {
  const now = new Date();
  if (d.getTime() < now.getTime()) {
    return "overdue!";
  }
  if (d.getDate() === now.getDate() && d.getMonth() === now.getMonth()) {
    // Today, but still crazy early
    if (d.getHours() < 8) {
      return "bedtime";
    }
    if (d.getHours() < 17) {
      return "midday";
    }
    return "bedtime";
  }
  if (d.getTime() - now.getTime() < time.DAY) {
    // Tomorrow
    if (d.getHours() < 8) {
      return "bedtime";
    }
    if (d.getHours() < 17) {
      return "tomorrow midday";
    }
    return "tomorrow night";
  }
  if (d.getTime() - now.getTime() < 3 * time.DAY) {
    // 3 days, return day of week + time
    return d
      .toLocaleString("en-US", {
        weekday: "short",
      })
      .replace("AM", "am")
      .replace("PM", "pm");
  }

  return d.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    weekday: "short",
  });
}

export function fakePlural(units: string, v: number): string {
  if (v === 1 && units[units.length - 1] === "s") {
    return units.slice(0, units.length - 1);
  }
  return units;
}

export function shortFormatWithUnits(
  rawValue: number,
  units: string | undefined
) {
  return formatWithUnits(rawValue, units, true);
}

export function formatWithUnits(
  rawValue: number,
  units: string | undefined,
  short?: boolean
): string {
  const value = formatLargeNumber(rawValue);

  if (!units) {
    units = "units";
  }

  if (units === "minutes") {
    const hours = Math.floor(Math.abs(rawValue) / 60);
    const minutes = Math.floor(Math.abs(rawValue) - hours * 60);
    const prefix = rawValue < 0 ? "-" : "";
    if (hours === 0) {
      return `${prefix}${minutes}m`;
    }
    return `${prefix}${hours}h${minutes}`;
  }

  if (units === "$") {
    return `$${value}`;
  }
  if (short) {
    return value;
  }
  return `${value} ${fakePlural(units, rawValue)}`;
}

export function formatLargeNumber(v: number): string {
  let formatted = numeral(v).format("0.[00]a");
  // 3 sig figs is sufficient....
  // ABC.Dk -> ABCk
  if (formatted.length > 5) {
    formatted = formatted.replace(/\.[0-9]+/, "");
  }
  return formatted;
}

export function formatDuration(timeMs: number): string {
  const timeSeconds = timeMs / time.SECOND;
  if (timeSeconds < 60) {
    return "0";
  }
  const timeMinutes = timeMs / time.MINUTE;
  if (timeMinutes < 60) {
    return `${timeMinutes.toFixed(0)} min`;
  }
  const timeHours = timeMs / time.HOUR;
  if (timeHours < 24) {
    return `${timeHours.toFixed(1)} hr`;
  }
  const timeDays = timeMs / time.DAY;
  if (timeDays < 14) {
    return `${timeDays.toFixed(1)} day`;
  }
  const timeWeeks = timeMs / time.WEEK;
  if (timeWeeks < 14) {
    return `${timeWeeks.toFixed(1)} wk`;
  }

  const timeMonths = timeMs / time.MONTH;
  if (timeMonths < 18) {
    return `${timeMonths.toFixed(1)} mo`;
  }

  const timeYears = timeMs / time.YEAR;
  if (timeYears < 50) {
    return `${timeYears.toFixed(1)} yr`;
  }
  return `∞`;
}
