import React from "react";
import "styles/App.css";
import { Provider } from "react-redux";
import InboxScreen from "components/App";
import store from "client/data/redux_store";
import { initFirebase } from "client/firebase/firebase";
import { BrowserRouter as Router } from "react-router-dom";

initFirebase();

export default class AppContainer extends React.Component<{}, {}> {
  render() {
    return (
      <div className="App">
        <div className="App-body">
          <Router>
            <Provider store={store}>
              <InboxScreen />
            </Provider>
          </Router>
          <div id="firebaseui-auth-container" />
        </div>
      </div>
    );
  }
}
