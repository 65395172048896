import { StoredTarget, Target } from "client/firebase/models/target";
import * as time from "lib/date_utils/time";
import { isMobile } from "lib/device/mobile";
import { formatDuration, formatWithUnits, simpleTime } from "lib/format";
import React from "react";
import { addDelta } from "lib/date_utils/arithmetic";
import {
  computeDateVal,
  getLatestTarget,
  interpolate,
  Result,
} from "client/firebase/models/result";
import { getTodayCutoff } from "client/dagbok/analysis/days";
import { Progress } from "client/firebase/models/progress";

export function computeTimeDelta(
  target: StoredTarget,
  current: number,
  step: number
): { timeDelta: number; valueDelta: number } {
  step = Math.min(step, Math.abs(target.endValue - current));

  if (target.endValue === current) {
    return { timeDelta: Number.MAX_SAFE_INTEGER, valueDelta: 0 };
  }

  const nextUnit = target.endValue > target.startValue ? step : -step;

  const currentExpectedTime = interpolate(
    target.startValue,
    target.startDate.getTime(),
    target.endValue,
    target.endDate.getTime() + time.MINUTE,
    current + nextUnit
  );

  return { timeDelta: currentExpectedTime - Date.now(), valueDelta: step };
}

function expandAndClamp(target: Target, targetExpected: number): number {
  if (target.endValue > target.startValue) {
    return Math.min(target.endValue, Math.ceil(targetExpected));
  }
  return Math.max(target.endValue, Math.floor(targetExpected));
}

function getNextStep(
  target: Target,
  current: number,
  delta: number,
  step: number
): { nextStep: number; targetDate: Date } {
  const todayEnd = getTodayCutoff();

  if (delta < 0) {
    // Figure out when doing "step" by would get us caught up to no longer
    // be in "today"
    const targetDate = addDelta(new Date(), todayEnd);
    const nextUnit = target.endValue > target.startValue ? step : -step;
    let targetExpected = computeDateVal(target, targetDate) - nextUnit;
    let reasonableTarget = expandAndClamp(target, targetExpected);

    let actualExpectedDate = new Date(
      interpolate(
        target.startValue,
        target.startDate.getTime(),
        target.endValue,
        target.endDate.getTime() + time.MINUTE,
        reasonableTarget
      )
    );

    // Doing "step" by *now* would get us caught up
    if (actualExpectedDate.getTime() < Date.now()) {
      actualExpectedDate = addDelta(new Date(), todayEnd / 2);
      reasonableTarget = computeDateVal(target, actualExpectedDate);
    }

    return {
      targetDate: actualExpectedDate,
      nextStep: Math.abs(reasonableTarget - current),
    };
  }

  return {
    nextStep: step,
    targetDate: addDelta(new Date(), delta),
  };
}

export function computeFormattedTimeDelta(
  target: Target,
  current: number,
  step: number
): { timeDeltaClass: string; timeDelta: string; stepInfo?: JSX.Element } {
  if (
    (current >= target.endValue && target.endValue > target.startValue) ||
    (current <= target.endValue && target.endValue < target.startValue)
  ) {
    return {
      timeDeltaClass: "time-delta-ahead",
      timeDelta: "Done!",
      stepInfo: <span>Done!</span>,
    };
  }

  const { timeDelta, valueDelta } = computeTimeDelta(target, current, step);

  let timeDeltaClass;
  if (timeDelta > 24 * time.HOUR) {
    timeDeltaClass = "time-delta-ahead";
  } else if (timeDelta < -18 * time.HOUR) {
    timeDeltaClass = "time-delta-behind";
  } else {
    timeDeltaClass = "time-delta-okay";
  }

  const prefix = timeDelta < 0 ? "-" : "+";

  const { nextStep, targetDate } = getNextStep(
    target,
    current,
    timeDelta,
    valueDelta
  );

  const stepInfo = (
    <span>
      <span className={isMobile() ? timeDeltaClass : ""}>
        {formatWithUnits(nextStep, target.units)}
      </span>
      <br className={"mobile-only"} /> by {simpleTime(targetDate)}
    </span>
  );

  let formattedTimeDelta = prefix + formatDuration(Math.abs(timeDelta));

  if (target.startDate > new Date()) {
    formattedTimeDelta = "not started";
  }

  if (Math.abs(timeDelta) < 5 * time.MINUTE) {
    return {
      timeDeltaClass,
      timeDelta: "now",
      stepInfo,
    };
  }

  return { timeDeltaClass, timeDelta: formattedTimeDelta, stepInfo };
}

export function computeResultTimeDelta(
  result: Result,
  progress: Progress[]
): { timeDelta: number; valueDelta: number } {
  const current = progress.length
    ? progress[progress.length - 1].computedValue ?? 0
    : 0;
  const lastTarget = getLatestTarget(result);
  if (lastTarget === undefined) {
    return { timeDelta: Number.MAX_SAFE_INTEGER - 1, valueDelta: 0 };
  }
  return computeTimeDelta(lastTarget, current, result.expectedStep);
}

export function formattedStepTime(result: Result): string {
  const latestTarget = getLatestTarget(result);
  if (!latestTarget || latestTarget.endValue === latestTarget.startValue) {
    return "";
  }
  const timeDelta =
    (result.expectedStep *
      (latestTarget.endDate.getTime() - latestTarget.startDate.getTime())) /
    (latestTarget.endValue - latestTarget.startValue);
  return `(every ${formatDuration(Math.abs(timeDelta))})`;
}
