import React, { useState } from "react";
import {
  computeEndFromRate,
  computeRate,
  Result,
  updatePreferredRateUnit,
  updateTargetFields,
  updateUnits,
} from "client/firebase/models/result";
import * as time from "lib/date_utils/time";
import _ from "lodash";
import { Target } from "client/firebase/models/target";
import { MagicInput } from "components/MagicInput";
import { timeUnitToText } from "lib/date_utils/time";

interface Props {
  result: Result;
  target?: Target;
  simple?: boolean;
}

const timeUnitCycle = [time.DAY, time.WEEK, time.MONTH, time.YEAR];

function toggleTimeUnit(
  resultId: string,
  timeUnit: number,
  setTimeUnit: (value: number) => void
) {
  const curIndex = _.indexOf(timeUnitCycle, timeUnit);
  const newTimeUnit = timeUnitCycle[(curIndex + 1) % timeUnitCycle.length];
  updatePreferredRateUnit(resultId, newTimeUnit);
  setTimeUnit(newTimeUnit);
}

const TargetRateIndicator = (props: Props) => {
  const { target, simple, result } = props;
  const [timeUnit, setTimeUnit] = useState(
    result.preferredRateUnit || time.DAY
  );

  if (!target) {
    return <span>no target</span>;
  }
  const rateVal = computeRate(target, timeUnit);
  const absRateVal = Math.abs(rateVal);
  return (
    <span>
      (
      {simple ? (
        <span>{absRateVal.toFixed(1)}</span>
      ) : (
        <MagicInput
          className="target-rate-input"
          initialValue={absRateVal.toFixed(1)}
          onSubmit={(newRateStr) => {
            let newRate = parseFloat(newRateStr) || 0;
            if (rateVal < 0) {
              newRate = -1 * Math.abs(newRate);
            }

            const { endDate, endValue } = computeEndFromRate(
              result,
              target,
              timeUnit,
              newRate
            );
            if (endDate !== target?.endDate || endValue !== target?.endValue) {
              updateTargetFields(target, { endDate, endValue });
            }
          }}
          validate={(v) => !!parseFloat(v)}
          controlled
        />
      )}
      {simple ? (
        <span> {target.units} </span>
      ) : (
        <MagicInput
          initialValue={target.units}
          placeholder="units"
          className="target-units-input"
          onSubmit={(v) => {
            updateUnits(target.resultId, v);
          }}
        />
      )}
      /{" "}
      <span
        onClick={() => {
          toggleTimeUnit(result.id, timeUnit, setTimeUnit);
        }}
      >
        {timeUnitToText(timeUnit)}
      </span>
      )
    </span>
  );
};

export default TargetRateIndicator;
