import React, { useEffect } from "react";
import { connect } from "react-redux";
import { State } from "client/data/state";
import _ from "lodash";
import { Progress } from "client/firebase/models/progress";
import { Result } from "client/firebase/models/result";
import { RateGraph } from "components/RateGraph";
import { StackedProgressGraph } from "components/StackedProgressGraph";
import { Breadcrumbs, splitCrumbs } from "components/Breadcrumbs";
import { createGroups } from "components/ResultListPage";
import { ResultList, ResultWithProgress } from "components/ResultList";
import { WEEK } from "lib/date_utils/time";

interface PureProps {
  category: string;
  progress: Progress[];
  results: Result[];
}
export const PureCategoryInfo = ({
  category,
  progress,
  results,
}: PureProps) => {
  useEffect(() => {
    document.title = `Dagbok - ${category}`;
  });
  const resultFilter = (r: ResultWithProgress) => !r.result.complete;

  return (
    <div className="category-info">
      <div className="category-crumbs">
        <Breadcrumbs crumbs={splitCrumbs("/category", category)} lastCurrent />
      </div>
      <hr />
      <ResultList
        groups={createGroups(results, progress, resultFilter, category)}
      />
      <hr />
      <StackedProgressGraph
        key={category}
        progress={progress}
        results={results}
      />
      <hr />
      <RateGraph progress={progress} unit={"units"} timeUnit={WEEK} />
    </div>
  );
};

interface Props {
  category: string;
}
export default connect(
  (
    { progress: { items: progressItems }, result: { items } }: State,
    { category }: Props
  ) => {
    const results = items.filter((r) => r.category?.indexOf(category) === 0);
    const resultIdsSet = _.keyBy(results, "id");
    return {
      results,
      progress: progressItems.filter((p) => p.resultId in resultIdsSet),
    };
  },
  (_) => ({})
)(PureCategoryInfo);
