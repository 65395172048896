// Action creators
import ProgressStore, {
  deleteProgress,
  parseProgressText,
  Progress,
  ProgressType,
  updateProgress,
} from "client/firebase/models/progress";
import { Dispatch } from "redux";
import { ActionType } from "client/data/actions/actions";
import {
  getOrCreateResultId,
  getResultProgress,
} from "client/data/actions/result";

export function doUpdateProgress() {
  return function (id: string, text: string, date: Date) {
    if (text === "delete") {
      deleteProgress(id);
    } else {
      updateProgress(id, text, date).then();
    }
  };
}

export const overwriteProgressAction = (progress: Progress[]) => ({
  type: ActionType.OverwriteProgress,
  progress: progress,
});

export function overwriteProgressFromServer(dispatch: Dispatch) {
  ProgressStore.fetchAll().then((progress) =>
    dispatch(overwriteProgressAction(progress))
  );
}

export function subscribeToProgress(dispatch: Dispatch) {
  ProgressStore.subscribeAll((progress) => {
    dispatch(overwriteProgressAction(progress));
  });
}

export function doCreateProgress() {
  return function (
    text: string,
    date: Date,
    navigateResult: (resultID: string) => void
  ) {
    if (text) {
      const parsed = parseProgressText(text);
      if (!parsed) {
        throw Error("Received malformed progress.ts text");
      }
      const { action, value, type } = parsed;

      getOrCreateResultId(action).then((resultId) => {
        getResultProgress(resultId).then((progress) => {
          progress = progress.filter((p) => p.date.getTime() < date.getTime());
          let computedValue = value;
          let computedDelta = value;
          if (type === ProgressType.Delta) {
            if (progress.length) {
              computedValue += progress[progress.length - 1].computedValue || 0;
            }
          } else {
            if (progress.length) {
              computedDelta -= progress[progress.length - 1].computedValue || 0;
            } else {
              computedDelta = 0;
            }
          }

          ProgressStore.add({
            text: text,
            comment: "",
            action,
            resultId,
            date,
            type: type,
            value: value,
            computedValue,
            computedDelta,
          }).then(() => {
            navigateResult(resultId);
          });
        });
      });
    }
  };
}
