import React, { useState } from "react";
import { Progress } from "client/firebase/models/progress";
import _ from "lodash";
import * as time from "lib/date_utils/time";
import { timeUnitToText } from "lib/date_utils/time";
import { addDelta, startOfDay } from "lib/date_utils/arithmetic";
import { MagicInput } from "components/MagicInput";
import {
  AUTO_VIEWPORT,
  DateValue,
  GraphboardView,
  GraphLine,
  Layer,
} from "components/graphboard/GraphboardView";
import { getDimensions } from "components/graph";
import { GraphSegment } from "components/graphboard/segment";
import { computeRate, getTargets, Result } from "client/firebase/models/result";
import COLOR from "lib/color";
import { Target } from "client/firebase/models/target";

interface Props {
  result?: Result; // If set, plots targets as horizontal lines
  progress: Progress[];
  unit: string;
  timeUnit: number;
}

interface RateInfo {
  windowSize: number;
  label: string;
  color: string;
}

const defaultRateInfo: RateInfo[] = [
  { windowSize: 14 * time.DAY, label: "14 days", color: "#bbbbbb" },
  { windowSize: 30 * time.DAY, label: "30 days", color: "#999999" },
  { windowSize: 60 * time.DAY, label: "60 days", color: "#777777" },
  { windowSize: 90 * time.DAY, label: "90 days", color: "#000000" },
];

function createRatePoints(
  progress: Progress[],
  timeUnit: number,
  windowSize: number
): DateValue[] {
  const windowStep = time.DAY;
  const windowScale = timeUnit;
  if (progress.length === 0) {
    return [];
  }

  progress = _.sortBy(progress, "date");
  const points: DateValue[] = [];

  const lastDate = progress[progress.length - 1].date;

  // Initialize window
  let windowStartIndex = 0;
  let windowEndIndex = 0; // Exclusive
  let windowSum = 0;
  let windowEndDate = startOfDay(addDelta(progress[0].date, windowSize));

  if (windowEndDate.getTime() > Date.now()) {
    windowEndDate = new Date();
  }

  while (
    windowEndIndex < progress.length &&
    progress[windowEndIndex].date < windowEndDate
  ) {
    windowSum += progress[windowEndIndex].computedDelta || 0;
    windowEndIndex++;
  }
  points.push({
    date: windowEndDate,
    value: (windowSum / windowSize) * windowScale,
  });

  // Record + iterate window
  while (windowEndDate < lastDate) {
    // Shift window by step
    windowEndDate = addDelta(windowEndDate, windowStep);
    // Remove old elements
    while (
      windowStartIndex < progress.length &&
      progress[windowStartIndex].date < addDelta(windowEndDate, -windowSize)
    ) {
      windowSum -= progress[windowStartIndex].computedDelta || 0;
      windowStartIndex++;
    }
    // Add new elements
    while (
      windowEndIndex < progress.length &&
      progress[windowEndIndex].date < windowEndDate
    ) {
      windowSum += progress[windowEndIndex].computedDelta || 0;
      windowEndIndex++;
    }
    points.push({
      date: windowEndDate,
      value: (windowSum / windowSize) * windowScale,
    });
  }
  return points;
}

function createMultiRateTable(
  progress: Progress[],
  timeUnit: number,
  rateInfo: RateInfo[]
): GraphLine[] {
  return _.compact(
    rateInfo.map(({ windowSize, label, color }) => {
      const points = createRatePoints(progress, timeUnit, windowSize);
      if (points.length == 0) {
        return false;
      }
      return {
        type: "line",
        name: label,
        points,
        style: { color: { hex: color } },
      };
    })
  );
}

export const RateGraph = ({ progress, timeUnit, result }: Props) => {
  const [minRollupDays, setMinRollupDays] = useState(14);
  const minRollup = minRollupDays * time.DAY;

  const rateInfo = defaultRateInfo.filter(
    ({ windowSize }) => windowSize > minRollup
  );

  const rateElements = createMultiRateTable(progress, timeUnit, [
    ...rateInfo,
    { windowSize: minRollup, label: "min rollup", color: "#44BBD0FF" },
  ]);

  const rateLines: GraphSegment[] = createRateLines(result, timeUnit);

  const layers: Layer[] = [
    {
      elements: rateLines,
      style: { strokeWidth: 8, strokeCap: "round" },
    },
    {
      elements: rateElements,
    },
  ];
  const size = getDimensions();

  return (
    <div className="result-graph">
      <b>Blue rollup period: </b>
      <MagicInput
        className="rate-rollup-entry"
        initialValue={minRollupDays.toFixed(0)}
        onSubmit={(v) => {
          const newRate = parseInt(v) || 7;
          if (newRate > 0) {
            setMinRollupDays(newRate);
          }
        }}
      />
      <span> days</span>
      <GraphboardView viewport={AUTO_VIEWPORT} layers={layers} size={size} />
    </div>
  );
};

export function addYAxis(spec: any, units: string, timeUnit: number): any {
  const obj = JSON.parse(JSON.stringify(spec));
  obj.encoding.y.axis.title = `${units} per ${timeUnitToText(timeUnit)}`;
  return obj;
}

function createRateLines(
  result: Result | undefined,
  timeUnit: number
): GraphSegment[] {
  if (!result) {
    return [];
  }
  const allTargets = getTargets(result);
  return allTargets.map((target: Target) => {
    const { startDate, endDate } = target;
    const rate = computeRate(target, timeUnit);
    const out: GraphSegment = {
      type: "segment",
      name: target.id,
      endDate,
      endY: rate,
      startDate,
      startY: rate,
      style: { color: COLOR.Green },
    };
    return out;
  });
}
