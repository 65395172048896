export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 30 * DAY;
export const YEAR = 365 * DAY;

const timeUnitText = {
  [DAY]: "day",
  [WEEK]: "week",
  [MONTH]: "month",
  [YEAR]: "year",
};

export function timeUnitToText(unit: number) {
  return timeUnitText[unit] || "?";
}
