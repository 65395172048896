import React, { useEffect } from "react";
import { getLatestTarget, Result } from "client/firebase/models/result";
import { Progress } from "client/firebase/models/progress";
import { connect } from "react-redux";
import { State } from "client/data/state";
import _ from "lodash";
import {
  ResultGroup,
  ResultList,
  ResultWithProgress,
} from "components/ResultList";
import { Link, useLocation } from "react-router-dom";
import { computeResultTimeDelta } from "client/dagbok/analysis/steps";
import {
  getDueCutoff,
  getTodayCutoff,
  getTomorrowCutoff,
} from "client/dagbok/analysis/days";
import { LinkOrButton } from "components/LinkOrButton";
import { endOfDay, morningAfter } from "lib/date_utils/arithmetic";

interface Props {
  results: Result[];
  progress: Progress[];
  loaded: boolean;
}

const PureResultListPage = ({ results, progress, loaded }: Props) => {
  useEffect(() => {
    document.title = `Dagbok`;
  });

  const loc = useLocation();
  const now = new Date();
  const endOfTomorrow = endOfDay(morningAfter(now));

  // 4pm today if morning; Midnight if afternoon
  const dueCutoff = getDueCutoff();
  // Today = through noon tomorrow (if < 4am)
  const todayCutoff = getTodayCutoff();
  // Tomorrow = through noon 2 days from now
  const tomorrowCutoff = getTomorrowCutoff();

  let due = loc.pathname.indexOf("/due") >= 0;
  let today = loc.pathname.indexOf("/today") >= 0;
  let tomorrow = loc.pathname.indexOf("/tomorrow") >= 0;
  let complete = loc.pathname.indexOf("/complete") >= 0;
  let active = loc.pathname.indexOf("/active") >= 0;
  let later = loc.pathname.indexOf("/later") >= 0;
  let noGoal = loc.pathname.indexOf("/no-goal") >= 0;
  let all = loc.pathname.indexOf("/all") >= 0;
  if (
    !due &&
    !today &&
    !complete &&
    !all &&
    !tomorrow &&
    !noGoal &&
    !later &&
    !active
  ) {
    today = true;
  }

  const snoozed = (r: ResultWithProgress) => (r.result.snoozedUntil || 0) > now;

  let filter = (_: ResultWithProgress) => true;
  let emptyTitle = <div>Let's work on something!</div>;
  let emptySubtitle = <div>Add a result above.</div>;
  let emptyIcon = "icon-arrow-up";
  if (due) {
    filter = (r) =>
      r.timeDelta < dueCutoff && !r.result.complete && !snoozed(r);
    emptyIcon = "icon-check";
    emptyTitle = <div>Nothing due!</div>;
    emptySubtitle = (
      <div>
        Check what's for <Link to="/results/today">today.</Link>
      </div>
    );
  } else if (today) {
    filter = (r) =>
      r.timeDelta < todayCutoff && !r.result.complete && !snoozed(r);
    emptyIcon = "icon-face-happy";
    emptyTitle = <div>You're caught for today!</div>;
    emptySubtitle = (
      <div>
        Check what's next for <Link to="/results/tomorrow">tomorrow.</Link>
      </div>
    );
  } else if (tomorrow) {
    filter = (r) =>
      (todayCutoff < r.timeDelta || snoozed(r)) &&
      r.timeDelta < tomorrowCutoff &&
      !r.result.complete &&
      (r.result.snoozedUntil || 0) < endOfTomorrow;
    emptyIcon = "icon-heart-hollow";
    emptyTitle = <div>Nothing new for tomorrow!</div>;
    emptySubtitle = (
      <div>
        See <Link to="/results/active">all active results.</Link>
      </div>
    );
  } else if (later) {
    filter = (r) =>
      (r.timeDelta > tomorrowCutoff ||
        (r.result.snoozedUntil || 0) > endOfTomorrow) &&
      !r.result.complete &&
      getLatestTarget(r.result) !== undefined;
    emptyIcon = "icon-sync";
    emptyTitle = <div>Nothing you're very on top of</div>;
    emptySubtitle = (
      <div>
        See <Link to="/results/active">all active results.</Link>
      </div>
    );
  } else if (complete) {
    filter = (r) => !!r.result.complete;
    emptyIcon = "icon-sync";
    emptyTitle = <div>Haven't finished anything yet!</div>;
    emptySubtitle = (
      <div>
        See <Link to="/results/active">all active results.</Link>
      </div>
    );
  } else if (active) {
    filter = (r) => !r.result.complete;
    emptyIcon = "icon-arrow-up";
    emptyTitle = <div>No active results</div>;
    emptySubtitle = <div>Add a result above.</div>;
  } else if (noGoal) {
    filter = (r) =>
      !r.result.complete && getLatestTarget(r.result) === undefined;
    emptyIcon = "icon-sync";
    emptyTitle = <div>All active results have goals</div>;
    emptySubtitle = (
      <div>
        See <Link to="/results/active">all</Link>
      </div>
    );
  }

  // Hack to test empty states
  if (loc.hash.indexOf("empty") >= 0) {
    filter = (_) => false;
  }

  const LoadingRow = (
    <div className="loading-item">
      <span className="glow-text">
        <span>Loading</span> <span>cool</span> <span>state</span>{" "}
      </span>
      <span
        className="glow-text"
        style={{ flex: 1, textAlign: "right", marginRight: "1rem" }}
      >
        <span style={{ width: "50%" }}>XXXXXXXXXXXXX</span>
      </span>
    </div>
  );

  const groups = createGroups(results, progress, filter, "");

  return (
    <div>
      <div className="result-list-view-options">
        <LinkOrButton path="/results/due" selected={due} text="Due" />
        <LinkOrButton path="/results/today" selected={today} text="Today" />
        <LinkOrButton
          path="/results/tomorrow"
          selected={tomorrow}
          text="Tomorrow"
        />
        <LinkOrButton path="/results/later" selected={later} text="Later" />
        <LinkOrButton
          path="/results/no-goal"
          selected={noGoal}
          text="No Goal"
        />
        <br className="mobile-only" />
        <LinkOrButton path="/results/active" selected={active} text="Active" />
        <LinkOrButton
          path="/results/complete"
          selected={complete}
          text="Done"
        />
        <LinkOrButton path="/results/all" selected={all} text="All" />
      </div>
      <hr />
      {loaded ? (
        groups.length > 0 ? (
          <ResultList groups={groups} />
        ) : (
          <div className="wrapper-message">
            <span className={emptyIcon} />
            <div className="title-message">{emptyTitle}</div>
            <div className="subtitle-message">{emptySubtitle}</div>
          </div>
        )
      ) : (
        <div className="list-items">
          {LoadingRow}
          {LoadingRow}
          {LoadingRow}
          {LoadingRow}
          {LoadingRow}
          {LoadingRow}
        </div>
      )}
    </div>
  );
};

export function createGroups(
  results: Result[],
  progress: Progress[],
  filter: (r: ResultWithProgress) => boolean,
  categoryPrefix: string
): ResultGroup[] {
  const resultsWithDelta = combineResultsAndProgress(results, progress).filter(
    filter
  );

  const resultsByCategory = _.groupBy(
    resultsWithDelta,
    (r) => r.result.category ?? ""
  );

  return _.sortBy(
    _.toPairs(resultsByCategory).map(([category, results]) =>
      createGroup(category, results, categoryPrefix)
    ),
    [(r) => r.results[0].timeDelta, "label"]
  );
}

function createGroup(
  category: string,
  results: ResultWithProgress[],
  categoryPrefix: string
): ResultGroup {
  const sortedResults = _.sortBy(results, "timeDelta");

  let label = category ? category.replace(categoryPrefix, "") : "no category";
  if (label[0] === "/") {
    label = label.substring(1);
  }
  return {
    label,
    category: category,
    results: sortedResults,
  };
}

export function combineResultsAndProgress(
  results: Result[],
  progress: Progress[]
): ResultWithProgress[] {
  const progressByItem = _.mapValues(
    _.groupBy(progress, (p) => p.resultId),
    (p) => _.sortBy(p, "date")
  );
  return results.map((r) => attachTimeDelta(r, progressByItem[r.id] || []));
}

function attachTimeDelta(
  result: Result,
  progress: Progress[]
): ResultWithProgress {
  const { timeDelta, valueDelta } = computeResultTimeDelta(result, progress);
  return {
    result,
    progress,
    timeDelta,
    valueDelta,
  };
}

export default connect(
  ({ result: { items, loaded }, progress: { items: progress } }: State) => {
    return { results: items, progress, loaded };
  },
  (_) => ({})
)(PureResultListPage);
